import {
  shouldHideAppStore,
  shouldHideClaimsManagement,
  shouldHideContacts,
  shouldHideFoodProgram,
  shouldHideForNetwork,
  shouldHideForNonDirectors,
  shouldHideForNonFCCs,
  shouldHideIfNotEnabledOnMarketplace,
  shouldHideKinderSystemsAppStore,
  shouldHideMealRecording,
  shouldHideMenuPlanning,
  shouldHideParentHandbook,
} from './navigationUtils';
import { paths } from './paths';
import { FeatureFlagEnum, RouteNameEnum, RouteType } from './types';

import { MigrationReport } from '../Components/Admin/MigrationReport';
import {
  Admin,
  AgingReports,
  AppStore,
  Attendance,
  ClaimCreate,
  ClaimUpdate,
  ClaimsList,
  Dashboard,
  DebugPage,
  Devices,
  Documents,
  EnrolledFamilies,
  EnrollmentInvoicePage,
  EnrollmentsAdminPage,
  Finances,
  FinancialSummary,
  FoodProgram,
  InvoiceEditConfirm,
  InvoiceEditDetails,
  InvoiceEditStudents,
  Invoices,
  KinderConnectPage,
  LearningBeyondPaper,
  Locations,
  MealInput,
  MealPlanning,
  MealRecordCreate,
  MenuPlanning,
  MenuPlanningUpdate,
  ParentHandbook,
  ParentsListing,
  Profile,
  RecurringPlanEditDetails,
  RecurringPlans,
  Rooms,
  Staff,
  StaffDetails,
  StudentAddPage,
  StudentEnrollment,
  StudentEnrollmentSummary,
  Students,
  StudentsImport,
  TimeSpaceCalculator,
  Timesheet,
  Transactions,
  Welcome,
} from '../Pages';

const routes: RouteType[] = [
  {
    name: RouteNameEnum.WELCOME,
    path: paths.welcome,
    component: Welcome,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.WELCOME),
  },
  {
    name: RouteNameEnum.HOME,
    path: paths.home,
    component: Dashboard,
    featureFlag: FeatureFlagEnum.DASHBOARD,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.HOME),
  },
  {
    name: RouteNameEnum.MESSAGING,
    path: paths.external.messaging,
    shouldOpenInNewTab: true,
    permission: 'can_list_marketplace_links',
    featureFlag: FeatureFlagEnum.MARKETPLACE,
    shouldHide: () =>
      shouldHideForNetwork(RouteNameEnum.MESSAGES) ||
      shouldHideIfNotEnabledOnMarketplace('messaging') ||
      shouldHideForNonDirectors(),
  },
  {
    name: RouteNameEnum.DOCUMENTS,
    path: paths.documents,
    permission: 'can_list_forms',
    component: Documents,
    featureFlag: FeatureFlagEnum.DOCUMENTS,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.DOCUMENTS),
  },
  {
    name: RouteNameEnum.ENROLLED_FAMILIES,
    path: paths.enrolledFamilies,
    permission: 'can_list_students',
    component: EnrolledFamilies,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.ENROLLED_FAMILIES),
  },
  {
    name: RouteNameEnum.LOCATIONS,
    path: paths.locations,
    permission: 'can_edit_location', //This permission is not correct, but it's the only one that works
    component: Locations,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.LOCATIONS),
  },
  {
    name: RouteNameEnum.PARENT_HANDBOOK,
    path: paths.parentHandbook,
    component: ParentHandbook,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.PARENT_HANDBOOK) || !shouldHideParentHandbook(),
  },
  {
    name: RouteNameEnum.PROFILE,
    path: paths.profile,
    component: Profile,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.PROFILE),
  },
  {
    name: RouteNameEnum.ROOMS,
    path: paths.rooms,
    permission: 'can_list_rooms',
    component: Rooms,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.ROOMS),
  },
  {
    name: RouteNameEnum.SHARED_DEVICES,
    path: paths.devices,
    permission: 'can_list_devices',
    component: Devices,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.SHARED_DEVICES),
  },
  {
    name: RouteNameEnum.DEBUG_PAGE,
    path: paths.debug,
    component: DebugPage,
  },
  {
    name: RouteNameEnum.STAFF,
    path: paths.staff,
    permission: 'can_list_staff',
    component: Staff,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.STAFF),
  },
  {
    name: RouteNameEnum.STAFF_DETAILS,
    path: paths.staffDetails,
    permission: 'can_list_staff',
    component: StaffDetails,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.STAFF_DETAILS),
  },
  {
    name: RouteNameEnum.STUDENT_ADD,
    path: paths.studentAdd,
    permission: 'can_create_student',
    component: StudentAddPage,
    featureFlag: FeatureFlagEnum.STUDENTS,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.STUDENT_ADD),
  },
  {
    name: RouteNameEnum.STUDENT_ADD_CHILD,
    path: paths.studentAddChild,
    permission: 'can_create_student',
    component: StudentAddPage,
    featureFlag: FeatureFlagEnum.STUDENTS,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.STUDENT_ADD_CHILD),
  },
  {
    name: RouteNameEnum.STUDENT_ENROLLMENT,
    path: paths.studentEnrollment,
    permission: 'can_edit_student',
    component: StudentEnrollment,
    featureFlag: FeatureFlagEnum.STUDENTS,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.STUDENT_ENROLLMENT),
  },
  {
    name: RouteNameEnum.STUDENT_ENROLLMENT_SUMMARY,
    path: paths.studentEnrollmentSummary,
    permission: 'can_edit_student',
    component: StudentEnrollmentSummary,
    featureFlag: FeatureFlagEnum.STUDENTS,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.STUDENT_ENROLLMENT_SUMMARY),
  },
  {
    name: RouteNameEnum.STUDENT_ENROLLMENT_INVOICE,
    path: paths.studentEnrollmentInvoice,
    permission: 'can_edit_student',
    component: EnrollmentInvoicePage,
    featureFlag: FeatureFlagEnum.STUDENTS,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.STUDENT_ENROLLMENT_INVOICE),
  },
  {
    name: RouteNameEnum.STUDENTS,
    path: paths.students,
    permission: 'can_list_students',
    component: Students,
    featureFlag: FeatureFlagEnum.STUDENTS,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.STUDENTS),
  },
  {
    name: RouteNameEnum.STUDENTS_FILTER,
    path: paths.studentsFilter,
    permission: 'can_list_students',
    component: Students,
    featureFlag: FeatureFlagEnum.STUDENTS,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.STUDENTS_FILTER),
  },
  {
    name: RouteNameEnum.STUDENTS_IMPORT,
    path: paths.studentsImport,
    permission: 'can_edit_student',
    component: StudentsImport,
    featureFlag: FeatureFlagEnum.STUDENTS,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.STUDENTS_IMPORT),
  },
  {
    name: RouteNameEnum.CONTACTS,
    path: paths.parentsListing,
    permission: 'can_view_parents_details',
    component: ParentsListing,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.CONTACTS) || shouldHideContacts(),
  },
  // Meal Planning routes
  {
    name: RouteNameEnum.FOOD_PROGRAM_MEALS,
    path: paths.foodProgramMeals,
    permission: 'can_edit_student', //TODO: change this permission
    component: MealPlanning,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.CALENDAR),
  },
  {
    name: RouteNameEnum.FOOD_PROGRAM_MEAL_CREATE,
    path: paths.foodProgramMealCreate,
    permission: 'can_edit_student', //TODO: change this permission
    component: MealInput,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.FOOD_PROGRAM_MEALS),
  },
  {
    name: RouteNameEnum.FOOD_PROGRAM_MEAL_UPDATE,
    path: paths.foodProgramMealUpdate,
    permission: 'can_edit_student', //TODO: change this permission
    component: MealInput,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.FOOD_PROGRAM_MEALS),
  },
  // Meal record routes
  {
    name: RouteNameEnum.FOOD_PROGRAM_MEAL_RECORD_CREATE,
    path: paths.foodProgramMealRecordCreate,
    permission: 'can_edit_student', //TODO: change this permission
    component: MealRecordCreate,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.FOOD_PROGRAM_MEAL_RECORD_CREATE) || shouldHideMealRecording(),
  },
  // Menu planning routes
  {
    name: RouteNameEnum.FOOD_PROGRAM_MENU_PLANNING,
    path: paths.foodProgramMenuPlanning,
    permission: 'can_edit_student', //TODO: change this permission
    component: MenuPlanning,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.FOOD_PROGRAM_MENU_PLANNING) || shouldHideMenuPlanning(),
  },
  {
    name: RouteNameEnum.FOOD_PROGRAM_MENU_PLANNING_EDIT,
    path: paths.foodProgramMenuPlanningUpdate,
    permission: 'can_edit_student', //TODO: change this permission
    component: MenuPlanningUpdate,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.FOOD_PROGRAM_MENU_PLANNING_EDIT) || shouldHideMenuPlanning(),
  },
  // Claims management routes
  {
    name: RouteNameEnum.FOOD_PROGRAM_CLAIMS,
    path: paths.foodProgramClaims,
    permission: 'can_edit_student', //TODO: change this permission
    component: ClaimsList,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.FOOD_PROGRAM_CLAIMS) || shouldHideClaimsManagement(),
  },
  {
    name: RouteNameEnum.FOOD_PROGRAM_CLAIM_CREATE,
    path: paths.foodProgramClaimCreate,
    permission: 'can_edit_student', //TODO: change this permission
    component: ClaimCreate,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.FOOD_PROGRAM_CLAIMS) || shouldHideClaimsManagement(),
  },
  {
    name: RouteNameEnum.FOOD_PROGRAM_CLAIM_UPDATE,
    path: paths.foodProgramClaimUpdate,
    permission: 'can_edit_student', //TODO: change this permission
    component: ClaimUpdate,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.FOOD_PROGRAM_CLAIMS) || shouldHideClaimsManagement(),
  },
  // Reports routes
  {
    name: RouteNameEnum.AGING_REPORTS,
    path: paths.agingReports,
    permission: 'can_list_billing',
    component: AgingReports,
    featureFlag: FeatureFlagEnum.REPORTS,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.AGING_REPORTS),
  },
  {
    name: RouteNameEnum.ATTENDANCE,
    path: paths.attendance,
    permission: 'can_list_students',
    component: Attendance,
    featureFlag: FeatureFlagEnum.REPORTS,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.ATTENDANCE),
  },
  {
    name: RouteNameEnum.FINANCIAL_SUMMARY,
    path: paths.financialSummary,
    permission: 'can_list_billing',
    component: FinancialSummary,
    featureFlag: FeatureFlagEnum.REPORTS,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.FINANCIAL_SUMMARY),
  },
  {
    name: RouteNameEnum.STAFF_TIMESHEET,
    path: paths.staffTimesheet,
    permission: 'can_list_timecards',
    component: Timesheet,
    featureFlag: FeatureFlagEnum.REPORTS,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.STAFF_TIMESHEET),
  },
  {
    name: RouteNameEnum.FOOD_PROGRAM_REPORTS,
    path: paths.foodProgramReports,
    permission: 'can_list_transactions', // We should create a new permission for this
    component: FoodProgram,
    featureFlag: FeatureFlagEnum.REPORTS,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.FOOD_PROGRAM_REPORTS) || shouldHideFoodProgram(),
  },
  // Payments (billing) routes
  {
    name: RouteNameEnum.BILLING_SETTINGS,
    path: paths.billingSettings,
    permission: 'can_list_billing',
    component: Finances,
    featureFlag: FeatureFlagEnum.FINANCES,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.BILLING_SETTINGS),
  },
  {
    name: RouteNameEnum.INVOICES,
    path: paths.invoices,
    permission: 'can_list_billing',
    component: Invoices,
    featureFlag: FeatureFlagEnum.FINANCES,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.INVOICES),
  },
  {
    name: RouteNameEnum.INVOICE_EDIT_CONFIRM,
    path: paths.invoiceEditConfirm,
    permission: 'can_edit_billing',
    component: InvoiceEditConfirm,
    featureFlag: FeatureFlagEnum.FINANCES,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.INVOICE_EDIT_CONFIRM),
  },
  {
    name: RouteNameEnum.INVOICE_EDIT_DETAILS,
    path: paths.invoiceEditDetails,
    permission: 'can_edit_billing',
    component: InvoiceEditDetails,
    featureFlag: FeatureFlagEnum.FINANCES,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.INVOICE_EDIT_DETAILS),
  },
  {
    name: RouteNameEnum.INVOICE_EDIT_STUDENTS,
    path: paths.invoiceEditStudents,
    permission: 'can_edit_billing',
    component: InvoiceEditStudents,
    featureFlag: FeatureFlagEnum.FINANCES,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.INVOICE_EDIT_STUDENTS),
  },
  {
    name: RouteNameEnum.RECURRING_PLANS,
    path: paths.recurringPlans,
    permission: 'can_list_billing',
    component: RecurringPlans,
    featureFlag: FeatureFlagEnum.FINANCES,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.RECURRING_PLANS),
  },
  {
    name: RouteNameEnum.RECURRING_PLAN_EDIT_DETAILS,
    path: paths.recurringPlanEditDetails,
    permission: 'can_edit_billing',
    component: RecurringPlanEditDetails,
    featureFlag: FeatureFlagEnum.FINANCES,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.RECURRING_PLAN_EDIT_DETAILS),
  },
  {
    name: RouteNameEnum.TIME_SPACE_CALCULATOR,
    path: paths.timeSpaceCalculator,
    permission: 'can_list_billing',
    featureFlag: FeatureFlagEnum.FINANCES,
    component: TimeSpaceCalculator,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.TIME_SPACE_CALCULATOR) || shouldHideForNonFCCs(),
  },
  {
    name: RouteNameEnum.TRANSACTIONS,
    path: paths.transactions,
    permission: 'can_list_billing',
    component: Transactions,
    featureFlag: FeatureFlagEnum.FINANCES,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.TRANSACTIONS),
  },

  // Marketplace routes
  {
    name: RouteNameEnum.EVENTS,
    path: paths.external.events,
    shouldOpenInNewTab: true,
    permission: 'can_list_marketplace_links',
    featureFlag: FeatureFlagEnum.MARKETPLACE,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.EVENTS),
  },
  {
    name: RouteNameEnum.HELP_CENTER,
    path: paths.external.helpCenter,
    permission: 'can_list_marketplace_links',
    featureFlag: FeatureFlagEnum.HELP_CENTER,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.HELP_CENTER),
    shouldOpenInNewTab: true,
  },
  {
    name: RouteNameEnum.INTERESTED_FAMILIES,
    path: paths.external.interestedFamilies,
    shouldOpenInNewTab: true,
    permission: 'can_list_marketplace_links',
    featureFlag: FeatureFlagEnum.MARKETPLACE,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.INTERESTED_FAMILIES),
  },
  {
    name: RouteNameEnum.LOCATION_EDIT,
    path: paths.external.locationEdit,
    permission: 'can_list_marketplace_links',
    featureFlag: FeatureFlagEnum.MARKETPLACE,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.LOCATION_EDIT),
  },
  {
    name: RouteNameEnum.MARKETING,
    path: paths.external.marketing,
    shouldOpenInNewTab: true,
    permission: 'can_list_marketplace_links',
    featureFlag: FeatureFlagEnum.MARKETPLACE,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.MARKETING),
  },
  {
    name: RouteNameEnum.MESSAGES,
    path: paths.external.messages,
    shouldOpenInNewTab: true,
    permission: 'can_list_marketplace_links',
    featureFlag: FeatureFlagEnum.MARKETPLACE,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.MESSAGES) || shouldHideForNonDirectors(),
  },
  {
    name: RouteNameEnum.ONBOARDING,
    path: paths.external.onboarding,
    permission: 'can_list_marketplace_links',
    featureFlag: FeatureFlagEnum.MARKETPLACE,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.ONBOARDING) || shouldHideForNonDirectors(),
  },
  {
    name: RouteNameEnum.TOURS,
    path: paths.external.tours,
    shouldOpenInNewTab: true,
    permission: 'can_list_marketplace_links',
    featureFlag: FeatureFlagEnum.MARKETPLACE,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.TOURS),
  },
  {
    name: RouteNameEnum.TOUR_REQUESTS,
    path: paths.external.tourRequests,
    shouldOpenInNewTab: true,
    permission: 'can_list_marketplace_links',
    featureFlag: FeatureFlagEnum.MARKETPLACE,
    shouldHide: () =>
      shouldHideForNetwork(RouteNameEnum.TOUR_REQUESTS) || shouldHideIfNotEnabledOnMarketplace('tourRequests'),
  },
  {
    name: RouteNameEnum.UPDATE_WEBSITE,
    path: paths.external.updateWebsite,
    shouldOpenInNewTab: true,
    permission: 'can_list_marketplace_links',
    featureFlag: FeatureFlagEnum.MARKETPLACE,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.UPDATE_WEBSITE) || shouldHideForNonDirectors(),
  },
  {
    name: RouteNameEnum.VIEW_WEBSITE,
    shouldOpenInNewTab: true,
    path: paths.external.viewWebsite,
    permission: 'can_list_marketplace_links',
    featureFlag: FeatureFlagEnum.MARKETPLACE,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.VIEW_WEBSITE),
  },

  // Admin routes
  {
    name: RouteNameEnum.AREA_51,
    path: paths.area51,
    permission: 'can_list_moxit_organizations',
    component: Admin,
  },
  {
    name: RouteNameEnum.ADMIN_DOCUMENTS,
    path: paths.adminDocuments,
    permission: 'can_list_moxit_organizations',
    component: Admin,
  },
  {
    name: RouteNameEnum.USERS,
    path: paths.users,
    permission: 'can_list_moxit_organizations',
    component: Admin,
  },
  {
    name: RouteNameEnum.NETWORKS,
    path: paths.networks,
    permission: 'can_list_moxit_organizations',
    component: Admin,
  },
  {
    name: RouteNameEnum.PROVIDERS,
    path: paths.providers,
    permission: 'can_list_moxit_organizations',
    component: Admin,
  },
  {
    name: RouteNameEnum.ENROLLMENTS_ADMIN,
    path: paths.enrollmentsAdmin,
    permission: 'can_list_moxit_organizations',
    component: EnrollmentsAdminPage,
  },
  {
    name: RouteNameEnum.MIGRATION_REPORT,
    path: paths.migrationReport,
    permission: 'can_list_moxit_organizations',
    component: MigrationReport,
  },
  {
    name: RouteNameEnum.TEACHER_RECRUITMENT_FULL_TIME,
    path: 'https://staging.earlyday.com/employer/jobs',
    permission: 'can_list_moxit_organizations',
    shouldOpenInNewTab: true,
  },
  {
    name: RouteNameEnum.TEACHER_RECRUITMENT_SUBSTITUTE,
    path: 'https://childcarematters.com/jobs',
    permission: 'can_list_moxit_organizations',
    shouldOpenInNewTab: true,
  },
  {
    name: RouteNameEnum.LICENSING_MANAGEMENT,
    path: 'https://nm.staging.wonderschool.io/director/license/fcc',
    permission: 'can_list_moxit_organizations',
    shouldOpenInNewTab: true,
  },
  {
    name: RouteNameEnum.BUSINESS_COACHING,
    path: 'https://survey.wonderschool.com/',
    permission: 'can_list_moxit_organizations',
    shouldOpenInNewTab: true,
  },
  {
    name: RouteNameEnum.FORMS_BUILDER,
    path: paths.formsBuilder,
    permission: 'can_list_moxit_organizations',
  },
  {
    name: RouteNameEnum.SYSTEM_NOTIFICATIONS,
    path: paths.systemNotifications,
    permission: 'can_list_moxit_organizations',
    component: Admin,
  },
  // APPS
  {
    name: RouteNameEnum.APPS,
    path: paths.appstore,
    component: AppStore,
    featureFlag: FeatureFlagEnum.APP_STORE,
    permission: 'can_see_app_store',
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.APPS) || shouldHideAppStore(),
  },
  {
    name: RouteNameEnum.LEARNING_BEYOND_PAPER,
    path: paths.learningBeyondPaper,
    component: LearningBeyondPaper,
    featureFlag: FeatureFlagEnum.APP_STORE,
    shouldHide: () => shouldHideForNetwork(RouteNameEnum.APPS) || shouldHideAppStore(),
  },
  {
    name: RouteNameEnum.KINDER_CONNECT,
    path: paths.kinderConnect,
    component: KinderConnectPage,
    shouldHide: () => shouldHideAppStore() || shouldHideKinderSystemsAppStore(),
  },
];

export function getRoutes(): RouteType[] {
  return routes;
}
