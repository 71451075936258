import { useEffect, useState } from 'react';

// components
import { BusinessCoachCard, CoachMessageType, showToast, ToastTypeEnum } from '@wonderschool/common-base-ui';
import DocumentUploader from '../../Components/Upload/DocumentUploader';
import FileShareModal from './modals/FileShareModal';
import UploadProgressModal from './modals/UploadProgressModal';
import FormsAndDocumentsActionButtons from './StaffActionButtons';
import FormsAndDocumentsTable from './StaffDocumentsTable';

// hooks
import { useDownloadFiles, useGetFiles, useUpdateFile, useUploadFile } from '@wonderschool/file-service-client';
import { useTranslation } from 'react-i18next';
import { useOrganization } from '../../hooks/useOrganizations';

// util stuff
import {
  ParentHandbookConfirmationModal,
  ParentHandbookEditModal,
  SelectParentHandbookModal,
} from '@wonderschool/ccms-ui';
import { FileCategory, FileDocument, FileState, MalwareStatus } from '@wonderschool/common-base-types';
import { useParentHandbookQuery } from '@wonderschool/common-food-program-data-access';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLocation } from 'react-router-dom';
import mimeTypes from '../../helpers/mimeTypes';
import { RouteNameEnum, useRoutes } from '../../navigation';
import { logError } from '../../rollbar';
import { allowedMimeTypes, FILE_ACTIONS_CONSTS, UPLOAD_STATE_CONSTS } from '../dictionary';
import ParentHandbookModal from './modals/ParentHandbookModal';

// descriptive variable to mask passing a boolean argument to modal control function
const MODAL_STATE_OPEN = true;

const StaffMain: React.FC = () => {
  const { t } = useTranslation();
  const { uploadFile, error: uploadError, isSuccess: uploadSuccess, reset: resetUploadHook } = useUploadFile();
  const { downloadFiles, error: downloadError } = useDownloadFiles();
  const { updateFile } = useUpdateFile();
  const { id: currentOrganizationId } = useOrganization();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const showParentHandbookConfirmation = queryParams.get('showParentHandbookConfirmation');

  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const [uploadState, setUploadState] = useState<UPLOAD_STATE_CONSTS | null>(null);
  const [selectedDocuments, setSelectedDocuments] = useState<FileDocument[]>([]);
  const [openModalControl, setOpenModalControl] = useState<FILE_ACTIONS_CONSTS | null>(null);
  const [showParentHandbookModal, setShowParentHandbookModal] = useState(false);
  const [isEditParentHandbookModalOpen, setIsEditParentHandbookModalOpen] = useState(false);
  const [isSelectParentHandbookModalOpen, setIsSelectParentHandbookModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [parentHandbookToShare, setParentHandbookToShare] = useState<FileDocument | null>(null);
  const { gotoRouteByName } = useRoutes();
  const { parentHandbook: parentHandbookEnabled } = useFlags();
  const { data: parentHandbookResponse, loading: parentHandbookLoading } = useParentHandbookQuery();
  const { data: pdfDocuments } = useGetFiles({
    limit: 25,
    conditions: [
      {
        field: 'fileCategory',
        operator: 'in',
        value: [FileCategory.FORM],
      },
      {
        field: 'fileState',
        operator: '==',
        value: FileState.active,
      },
    ],
    orderBy: [
      {
        field: 'createdAt',
        direction: 'desc',
      },
    ],
  });

  const { data: parentHandbookDocuments } = useGetFiles({
    limit: 1, // * the provider should have a single parent handbook at a time
    conditions: [
      {
        field: 'fileCategory',
        operator: '==',
        value: FileCategory.PARENT_HANDBOOK,
      },
      {
        field: 'fileState',
        operator: '==',
        value: FileState.active,
      },
      {
        field: 'isAdmin',
        operator: '==',
        value: false,
      },
    ],
    orderBy: [
      {
        field: 'createdAt',
        direction: 'desc',
      },
    ],
  });

  const handleModalStates = (modalKey: FILE_ACTIONS_CONSTS, isOpen: boolean) => {
    setOpenModalControl(isOpen ? modalKey : null);
  };

  // upload stuff
  const handleFileSelectedForUpload = ({ data: file }: { data: File }) => {
    if (file) {
      setFileToUpload(file);
    }
  };

  const handleOpenParentHandbookModal = () => {
    setShowParentHandbookModal(true);
  };

  // Handle errors if download fails.
  useEffect(() => {
    if (!downloadError) return;

    // log it to rollbar
    logError('Documents - Download error', {
      status: downloadError?.status,
      message: downloadError?.message,
    });

    // show a toast
    showToast(ToastTypeEnum.Error, t('Error occurred while downloading file'));
  }, [downloadError, t]);

  // Handle errors if upload fails.
  useEffect(() => {
    if (!uploadError) return;

    // show the error modal state
    setUploadState(UPLOAD_STATE_CONSTS.error);
    setOpenModalControl(FILE_ACTIONS_CONSTS.upload);
    // log it with rollbar
    logError('Documents - Upload error: ', {
      serverMessage: uploadError,
    });
  }, [uploadError, setUploadState, setOpenModalControl]);

  // Handle upload success
  useEffect(() => {
    if (uploadSuccess && fileToUpload) {
      // show a toast
      showToast(
        ToastTypeEnum.Success,
        t('{{fileName}} has been successfully uploaded.', {
          fileName: fileToUpload?.name,
        })
      );
      // reset the file
      setFileToUpload(null);
      // reset the hook
      resetUploadHook();
    }
  }, [uploadSuccess, fileToUpload, resetUploadHook, setFileToUpload, t]);

  const handleFileSubmitted = async () => {
    if (!fileToUpload) {
      return null;
    }
    // set the modal content
    setUploadState(UPLOAD_STATE_CONSTS.uploading);
    // open progress modal
    handleModalStates(FILE_ACTIONS_CONSTS.upload, MODAL_STATE_OPEN);

    try {
      // start the request
      await uploadFile({
        file: fileToUpload,
        options: {
          fileAction: 'downloadable',
          fileCategory: FileCategory.FORM,
          metadata: {
            uploadPath: `/organizations/${currentOrganizationId}`,
          },
        },
      });

      // close the modal
      handleModalStates(FILE_ACTIONS_CONSTS.upload, !MODAL_STATE_OPEN);
    } catch (error) {
      // show the error modal state
      setUploadState(UPLOAD_STATE_CONSTS.error);
      // log it with rollbar
      logError('Documents - Upload error: ', {
        clientError: error,
        serverMessage: uploadError,
      });
    }

    return null;
  };

  // current docs stuff
  const handleSelectedDocuments = (selectedDocuments: FileDocument[]) => {
    setSelectedDocuments(selectedDocuments);
  };

  const handleFileActionClicked = (fileAction: FILE_ACTIONS_CONSTS) => {
    // Triggers file download.
    if (fileAction === FILE_ACTIONS_CONSTS.download) {
      downloadFiles(selectedDocuments.map((doc) => doc.id));
      return null;
    }

    handleModalStates(fileAction, true);
  };

  const navigateToParentHandbook = () => {
    gotoRouteByName(RouteNameEnum.PARENT_HANDBOOK);
  };

  const handleExistingParentHandbook = () => {
    setShowParentHandbookModal(false);
    setIsEditParentHandbookModalOpen(false);
    setIsSelectParentHandbookModalOpen(true);
  };

  const handleEditParentHandbook = () => {
    setIsEditParentHandbookModalOpen(true);
  };

  const setExistingPDFAsParentHandbook = async (fileId: FileDocument['id']) => {
    try {
      updateFile(
        {
          id: fileId,
          fileCategory: FileCategory.PARENT_HANDBOOK,
        },
        {
          onSuccess: () => {
            setIsConfirmationModalOpen(true);
          },
          onError: (error) => {
            showToast(ToastTypeEnum.Error, t('parentHandbook.documentSetError'));
            logError('Error setting the document as parent handbook', {
              clientError: error,
              fileId,
            });
          },
        }
      );
      setIsSelectParentHandbookModalOpen(false);
    } catch (error) {
      logError('Error setting the document as parent handbook', {
        clientError: error,
        fileId,
      });
    }
  };

  const handleNewParentHandbookUpload = async (file: File) => {
    try {
      await uploadFile({
        file,
        options: {
          fileAction: 'downloadable',
          fileCategory: FileCategory.PARENT_HANDBOOK,
          metadata: {
            uploadPath: `/organizations/${currentOrganizationId}`,
          },
        },
      });
      resetUploadHook();
      setIsSelectParentHandbookModalOpen(false);
      setIsConfirmationModalOpen(true);
    } catch (error) {
      logError('Error uploading parent handbook', {
        clientError: error,
        file,
      });
      showToast(ToastTypeEnum.Error, t('common.uploadFileError'));
    }
  };

  // Open confirmation modal when redirecting from parent handbook page
  useEffect(() => {
    if (showParentHandbookConfirmation === 'true') {
      setIsConfirmationModalOpen(true);
    }
  }, [showParentHandbookConfirmation]);

  const selectParentHandbookAndOpenShareModal = () => {
    setIsConfirmationModalOpen(false);
    clearParentHandbookConfirmationQueryParam();
    const parentHandbook =
      parentHandbookDocuments?.pages[0].documents.find((doc) => doc.fileCategory === FileCategory.PARENT_HANDBOOK) ||
      null;

    if (!parentHandbook) {
      showToast(ToastTypeEnum.Error, t('parentHandbook.documentShareError'));
      logError('Unable to find parent handbook file', {
        organizationId: currentOrganizationId,
      });
      return;
    }
    setParentHandbookToShare(parentHandbook);
    handleModalStates(FILE_ACTIONS_CONSTS.share, MODAL_STATE_OPEN);
  };

  const closeShareModal = () => {
    setParentHandbookToShare(null);
    handleModalStates(FILE_ACTIONS_CONSTS.share, !MODAL_STATE_OPEN);
  };

  const closeConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
    clearParentHandbookConfirmationQueryParam();
  };

  const clearParentHandbookConfirmationQueryParam = () => {
    queryParams.delete('showParentHandbookConfirmation');
    const newSearch = queryParams.toString();
    const newPath = `${location.pathname}${newSearch ? `?${newSearch}` : ''}`;
    window.history.replaceState(null, '', newPath);
  };

  return (
    <>
      {/* modals */}
      <>
        {openModalControl === FILE_ACTIONS_CONSTS.share && (
          <FileShareModal
            isModalOpen
            selectedDocuments={parentHandbookToShare ? [parentHandbookToShare] : selectedDocuments}
            closeModal={closeShareModal}
          />
        )}
        {openModalControl === FILE_ACTIONS_CONSTS.upload && (
          <UploadProgressModal
            isModalOpen
            uploadState={uploadState}
            filename={fileToUpload?.name || ''}
            closeModal={() => handleModalStates(FILE_ACTIONS_CONSTS.upload, !MODAL_STATE_OPEN)}
          />
        )}
      </>
      <div className="flex flex-col space-y-4">
        {parentHandbookEnabled && !parentHandbookLoading && !parentHandbookResponse?.parentHandbook?.id && (
          <>
            <BusinessCoachCard
              messageType={CoachMessageType.INFO}
              text={t('parentHandbook.ctaDescription')}
              name={t('parentHandbook.ctaTitle')}
              ctaText={t('parentHandbook.cta')}
              onCtaClick={handleOpenParentHandbookModal}
            />
          </>
        )}
        {showParentHandbookModal && (
          <ParentHandbookModal
            isModalOpen={showParentHandbookModal}
            closeModal={() => setShowParentHandbookModal(false)}
            onExistingParentHandbook={handleExistingParentHandbook}
          />
        )}
        {isEditParentHandbookModalOpen && (
          <ParentHandbookEditModal
            isOpen={isEditParentHandbookModalOpen}
            onClose={() => setIsEditParentHandbookModalOpen(false)}
            onEdit={navigateToParentHandbook}
            onExistingParentHandbook={handleExistingParentHandbook}
          />
        )}
        {isSelectParentHandbookModalOpen && (
          <SelectParentHandbookModal
            isOpen={isSelectParentHandbookModalOpen}
            onClose={() => setIsSelectParentHandbookModalOpen(false)}
            existingPDFFiles={
              pdfDocuments?.pages[0].documents.filter(
                (doc) => doc.file.mimetype === mimeTypes.PDF && doc.fileStatus === MalwareStatus.CLEAN
              ) ?? []
            }
            onNewParentHandbookUpload={handleNewParentHandbookUpload}
            onExistingPDFSelected={setExistingPDFAsParentHandbook}
          />
        )}
        {isConfirmationModalOpen && (
          <ParentHandbookConfirmationModal
            isOpen={isConfirmationModalOpen}
            onClose={closeConfirmationModal}
            onShare={selectParentHandbookAndOpenShareModal}
          />
        )}
        <div className="flex flex-col justify-between gap-y-4 md:flex-row">
          <p>{t('Upload, store and share documents with parents electronically here.')}</p>
          {/* UPLOADER */}
          {selectedDocuments.length === 0 && (
            <DocumentUploader
              floated="none"
              onFileSelected={handleFileSelectedForUpload}
              onSubmit={handleFileSubmitted}
              onError={() => null}
              shouldUseExternalStorageApi
              title={t('Uploading Files and Documents')}
              allowedFileTypes={allowedMimeTypes}
              maxNumberOfFiles={1}
            />
          )}
        </div>

        {/* forms/docs actions row */}
        <div className="flex flex-col">
          {/* SELECTED FILE ACTIONS */}
          {selectedDocuments.length > 0 && (
            <div data-testid="documents-action-btn-group" className="mt-4">
              <FormsAndDocumentsActionButtons
                areMultipleSelected={selectedDocuments.length > 1}
                handleFileActionClicked={handleFileActionClicked}
              />
            </div>
          )}
        </div>
        <div className="w-full">
          <FormsAndDocumentsTable
            onRowSelect={handleSelectedDocuments}
            onEditParentHandbook={handleEditParentHandbook}
          />
        </div>
      </div>
    </>
  );
};

export default StaffMain;
