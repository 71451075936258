import { EnvironmentEnum } from '@wonderschool/common-base-types';
import WSA from '../../WSA';

/**
 * This function returns the current environment as an EnvironmentEnum.
 * It is used to determine the environment-specific URL for the CCMS API.
 *
 * @returns The current environment as an EnvironmentEnum
 */
export function getCurrentEnvironment(): EnvironmentEnum {
  const env = WSA.env;
  switch (env) {
    case 'local':
      return EnvironmentEnum.local;
    case 'dev':
      return EnvironmentEnum.dev;
    case 'staging':
      return EnvironmentEnum.staging;
    case 'prod':
      return EnvironmentEnum.prod;
    default:
      throw new Error(`Unknown environment: ${env}`);
  }
}
