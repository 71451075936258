import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Menu } from 'semantic-ui-react';
import { getCurrentEnvironment } from '../../common/utils/environmentUtils';

// Import components.
import ChangeLanguage from '../../Components/Shared/ChangeLanguage';
import { routes } from '../../config/routes';
import { FeatureFlagsLink } from '../../feature-flags';
import UserProfileMenu from './UserProfileMenu';

import { NotificationsSidebar } from '@wonderschool/ccms-ui';
import { FoodProgramQueryProvider } from '@wonderschool/common-food-program-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import WSA from '../../WSA';

const TopNavigation = ({ viewport = 'desktop', handleShowMenu, history }) => {
  const { t } = useTranslation();
  const { isReady, isAuthenticated, authUser } = WSA.Components.useWSAuth();
  const environment = getCurrentEnvironment();
  const { isNotificationEnabled } = useFlags();

  // don't show if there's no user
  if (!isReady || !isAuthenticated) return null;

  const nav =
    viewport === 'mobile' ? (
      <Menu id="main-menu" stackable>
        <Menu.Item
          onClick={() => {
            handleShowMenu();
            history.push(routes.signout);
          }}
          content={t('Sign out')}
          style={{ cursor: 'pointer' }}
        />

        <Menu.Item>
          <FeatureFlagsLink mobile />
        </Menu.Item>

        <Menu.Item>
          <ChangeLanguage />
        </Menu.Item>

        <Menu.Item>
          <UserProfileMenu viewport="desktop" />
        </Menu.Item>
      </Menu>
    ) : (
      <Menu borderless className="top-nav">
        <Menu.Menu position="right">
          <Menu.Item>
            <FeatureFlagsLink mobile={false} />
          </Menu.Item>

          <Menu.Item>
            <ChangeLanguage />
          </Menu.Item>

          {isNotificationEnabled && (
            <Menu.Item>
              <FoodProgramQueryProvider
                getAuthToken={async () => await authUser.getIdToken()}
                environment={environment}
              >
                <NotificationsSidebar auth={WSA} />
              </FoodProgramQueryProvider>
            </Menu.Item>
          )}

          <UserProfileMenu />
        </Menu.Menu>
      </Menu>
    );

  return nav;
};

export default TopNavigation;
