import { WonderschoolPlanModal } from '@wonderschool/ccms-ui';
import { FoodProgramQueryProvider } from '@wonderschool/common-food-program-ui';
import WSA from '../../WSA';
import { getCurrentEnvironment } from '../../common/utils/environmentUtils';
import useOrganizationImpersonation from '../../hooks/useOrganizationImpersonation';
import { useAuthToken } from '../../hooks/useUser';

interface ApolloClientWrapperProps {
  children: React.ReactNode | React.ReactNode[];
}

export function ApolloClientWrapper({ children }: ApolloClientWrapperProps) {
  const { isAuthenticated, authUser } = WSA.Components.useWSAuth();
  const { impersonatedToken } = useAuthToken();
  const currentEnvironment = getCurrentEnvironment();
  const { isImpersonatingOrganization } = useOrganizationImpersonation();

  if (!isAuthenticated || !authUser) {
    return children;
  }

  return (
    <FoodProgramQueryProvider
      getAuthToken={async () => impersonatedToken ?? (await authUser.getIdToken())}
      environment={currentEnvironment}
    >
      <>
        {!isImpersonatingOrganization && <WonderschoolPlanModal />}
        {children}
      </>
    </FoodProgramQueryProvider>
  );
}
