import { parsePhoneNumber } from 'awesome-phonenumber';
import Validator from 'validator';
import { RELATIONSHIP_TYPES } from '../../consts';
import { ContactFormErrors, FamilyModalFormData } from './types';

type ValidateFamilyEditContactFormBooleanFlags = {
  shouldEditContact?: boolean;
  isEmailRequired?: boolean;
  selectedContact: boolean;
};

export function validateFamilyEditContactForm(
  formData: FamilyModalFormData,
  { shouldEditContact, selectedContact, isEmailRequired }: ValidateFamilyEditContactFormBooleanFlags
): ContactFormErrors {
  const errors: ContactFormErrors = {};

  if (shouldEditContact) {
    if (!formData.relationship) errors.relationship = 'familyModal.relationshipIsRequired';
    if (!formData.firstName) errors.firstName = 'common.firstNameRequired';
    if (!formData.lastName) errors.lastName = 'common.lastNameRequired';

    if (formData.phone) {
      const validityResult = parsePhoneNumber(formData.phone, { regionCode: 'US' });

      if (!Validator.isMobilePhone(formData.phone, 'en-US') || !validityResult.valid) {
        errors.phone = 'common.invalidPhone';
      }
    }
    if (!!formData.email && !Validator.isEmail(formData.email)) {
      errors.email = 'common.invalidEmail';
    } else if (isEmailRequired) {
      if (!formData.email || formData.email.indexOf('moxit_') > -1) errors.email = 'common.emailRequired';
    }

    if (
      formData.relationship &&
      formData.relationship !== RELATIONSHIP_TYPES.contact &&
      formData.isResponsibleForBilling &&
      (!formData.email || !Validator.isEmail(formData.email))
    ) {
      errors.email = 'familyModal.emailRequiredForBilling';
    }
  } else {
    if (!selectedContact) errors.selectedContact = 'familyModal.selectContact';
  }

  return errors;
}
