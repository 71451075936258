import { Spinner, WidgetSizeEnum } from '@wonderschool/common-base-ui';
import classNames from 'classnames/bind';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../../helpers/utils';
import { useProjectedRevenue } from '../../../hooks/useProjectedRevenue';
import { ShowErrors } from '../../Messages';
import styles from './ProjectedRevenue.module.scss';

const cx = classNames.bind(styles);

const ProjectedRevenueOverview = () => {
  const { t } = useTranslation();
  const { projectedRevenue, isLoading, error } = useProjectedRevenue();

  if (error) {
    return <ShowErrors content={error.message} />;
  }

  const months = Object.entries(projectedRevenue).sort(([dateA], [dateB]) => dayjs(dateA).diff(dayjs(dateB)));

  return (
    <div className={cx('mb-4 border-b pb-4')}>
      <div className={cx('header')}>
        <h3 className="text-xl">{t('projectedRevenue.header.title')}</h3>
      </div>
      {isLoading ? (
        <div className={cx('spinnerContainer')}>
          <Spinner size={WidgetSizeEnum.MEDIUM} />
        </div>
      ) : (
        // Wrap the entire grid in one box if needed:
        <div className={cx('box')}>
          <div className={cx('monthsGrid')}>
            {months.map(([date, amount]) => (
              <div key={date} className={cx('monthColumn')}>
                <div className={cx('monthLabel')}>{t(dayjs(date).format('MMMM').toLowerCase())}</div>
                <div className={cx('amount')}>{formatCurrency(amount)}</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectedRevenueOverview;
