import { Checkbox, Input, Tooltip } from '@wonderschool/common-base-ui';
import dayjs from 'dayjs';
import { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import RoomPicker from '../../../Components/Rooms/RoomPicker';
import { FormComponentProps } from '../../../common';
import { EnrollmentInformationType } from '../../types';

type EnrollmentInformationFormProps = FormComponentProps<EnrollmentInformationType> & {
  desiredStartDate?: string;
  desiredRoomId?: string;
};

function EnrollmentInformationForm({
  title,
  hasTitle = true,
  isSaving,
  onSave,
  desiredStartDate,
}: EnrollmentInformationFormProps) {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    setValue,
  } = useForm<EnrollmentInformationType>({
    defaultValues: { startDate: desiredStartDate ?? '', automaticallyEnroll: true },
  });

  useEffect(() => {
    if (desiredStartDate) {
      setValue('startDate', desiredStartDate);
    }
  }, [desiredStartDate, setValue]);

  const viewTitle = hasTitle ? t(title || 'students.enrollmentInformationFormTitle') : '';

  const onSubmit = useCallback(
    async (data: EnrollmentInformationType) => {
      const saveData = {
        ...data,
        startDate: data.startDate ? new Date(data.startDate) : undefined,
      };
      try {
        onSave?.({ data: saveData });
      } catch (error: any) {
        onSave?.({ errors: { message: error.message } });
      }
    },
    [onSave]
  );

  useEffect(() => {
    if (isSaving) {
      handleSubmit(onSubmit)();
    }
  }, [isSaving, handleSubmit, onSubmit]);

  return (
    <div className="enrollment-information-form">
      {!!viewTitle && <h2 className="mb-4 text-2xl font-bold">{viewTitle}</h2>}
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
        <div>
          <Controller
            name="room"
            control={control}
            rules={{ required: t('students.enrollment.validation.pleaseSelectARoom') }}
            render={({ field }) => (
              <RoomPicker
                {...field}
                required
                label={t('Room')}
                placeholder={t('Select a room')}
                error={errors.room?.message}
                data-testid="room-picker-field"
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="startDate"
            control={control}
            rules={{
              required: t('enrollments.enrollmentInfoStartDateRequired'),
              validate: {
                maxDate: (date) => dayjs(date).isBefore(dayjs('2100-12-31')) || t('enrollments.errors.maxDate'),
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                type="date"
                required
                label={t('enrollments.enrollmentInfoStartDateLabel')}
                error={errors.startDate?.message}
                data-testid="date-picker-field"
                max="2100-12-31"
                value={field.value as string}
              />
            )}
          />
        </div>
        <div></div>
        <div className="flex flex-wrap items-center">
          <Controller
            name="automaticallyEnroll"
            control={control}
            render={({ field }) => (
              <Checkbox
                {...register('automaticallyEnroll')}
                name={field.name}
                wrapperClasses="flex items-center"
                label={t('students.automaticallyEnrollLabel')}
                data-testid="enroll-automatically"
                checked={field.value}
              />
            )}
          />
          <Tooltip action="hover" content={t('students.automaticallyEnrollLabelTooltipContent')}>
            <span className="ml-2 cursor-pointer whitespace-normal text-blue-900">
              {t('students.automaticallyEnrollLabelTooltipTrigger')}
            </span>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

export default EnrollmentInformationForm;
