import { useFilesAccess } from '@wonderschool/file-service-client';
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';

// ui components
// eslint-disable-next-line no-restricted-imports
import { Form, Popup } from 'semantic-ui-react';

// hooks
import { useTranslation } from 'react-i18next';
import useRooms from '../../../hooks/useRooms';
import { useStudents } from '../../../students/studentsHooks';

// utils
import { FileCategory, FileDocument } from '@wonderschool/common-base-types';
import { Button, Dialog, Divider, InfoIcon } from '@wonderschool/common-base-ui';
import { ShowErrors } from '../../../Components/Messages';
import { showSuccessToast } from '../../../Components/Shared/showToast';
import { formatFullName } from '../../../helpers/utils';
import { useUser } from '../../../hooks/useUser';
import { SemanticFormProps } from '../../types';

const defaultFormValues = {
  selectedRooms: [],
  shareWithEntireLocation: false,
  selectedStudents: [],
  additionalNotes: '',
};

interface FileShareProps {
  isModalOpen: boolean;
  selectedDocuments?: FileDocument[];
  closeModal: () => void;
}

interface IErrors {
  shareWithRequired?: string;
  'share-error'?: string;
  additionalNotes?: string;
  [key: string]: string | undefined;
}

const FileShareModal: React.FC<FileShareProps> = ({
  selectedDocuments = [],
  isModalOpen = true,
  closeModal = () => undefined,
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState(defaultFormValues);
  const [errors, setErrors] = useState<IErrors>({});

  const rooms = useRooms();
  const { list: students } = useStudents();
  const { defaultLocation: currentLocation } = useUser();

  const {
    shareFiles,
    data: shareFilesData,
    isLoading: isSharing,
    error: shareError,
    isError: isShareError,
  } = useFilesAccess();

  const selectedFileIds = useMemo(() => selectedDocuments.map((doc) => doc.id), [selectedDocuments]);

  const resetForm = useCallback(() => {
    setData(defaultFormValues);
    setErrors({});
  }, []);

  const resetModal = useCallback(() => {
    resetForm();
    closeModal();
  }, [closeModal, resetForm]);

  // Sets ws-file-service error to local state
  useEffect(() => {
    if (!isShareError) return;
    setErrors({
      'share-error': `${shareError?.message}, status: ${shareError?.status}`,
    });
  }, [isShareError, shareError]);

  // Resets form values and errors when modal is closed
  useEffect(() => {
    // Closes modal if share response returns a success message.
    if (shareFilesData?.length) {
      showSuccessToast(
        'Success',
        t('{{ count }} file(s) shared successfully', {
          count: selectedFileIds.length,
        })
      );
      resetModal();
    }
  }, [resetModal, selectedFileIds.length, shareFilesData?.length, t]);

  function getNotesCharactersRemaining() {
    return data.additionalNotes ? 500 - data.additionalNotes.length : 500;
  }

  const updateForm = (_e: SyntheticEvent<HTMLElement, Event>, { name, value, checked }: SemanticFormProps) => {
    setData({
      ...data,
      [name]: checked || value,
    });

    const localErrors = { ...errors };

    delete localErrors[name];

    if (
      (name === 'selectedRooms' || name === 'selectedStudents' || name === 'shareWithEntireLocation') &&
      (checked || value?.length > 0) &&
      localErrors.shareWithRequired
    ) {
      delete localErrors.shareWithRequired;
    }

    setErrors(localErrors);
  };

  const onSubmit = () => {
    const isInvalid = validate();
    if (isInvalid) return null;

    // Prepare share data.
    const {
      shareWithEntireLocation = false,
      selectedRooms: rooms = [],
      selectedStudents: students = [],
      additionalNotes: message = '',
    } = data;

    shareFiles({
      files: selectedFileIds,
      students,
      rooms,
      locations: shareWithEntireLocation ? [currentLocation] : [],
      message,
    });
  };

  const validate = () => {
    const localErrors: IErrors = {};

    if (!selectedFileIds.length) {
      localErrors.shareWithRequired = t('Please select a file to share.');
    }

    if (data.additionalNotes?.length > 500) {
      localErrors.additionalNotes = t('Additional notes must 500 characters or less.');
    }
    if (data.selectedRooms.length === 0 && data.selectedStudents.length === 0 && !data.shareWithEntireLocation) {
      localErrors.shareWithRequired = t('Please choose an individual or group to share with.');
    }

    setErrors(localErrors);
    return Object.keys(localErrors).length > 0;
  };

  const studentList = useMemo(
    () =>
      students.map((student) => ({
        key: student.id,
        value: student.id,
        text: formatFullName(student, true),
        image: {
          avatar: true,
          src: student.picture,
        },
      })),
    [students]
  );

  return (
    <Dialog isOpen={isModalOpen} onClose={resetModal}>
      <Dialog.Panel>
        <Dialog.Title>
          {selectedDocuments.length === 1 && selectedDocuments[0].fileCategory === FileCategory.PARENT_HANDBOOK
            ? t('documents.staff.shareParentHandbook')
            : t('documents.staff.shareDocuments')}
        </Dialog.Title>
        <div className="my-6">
          <div className="flex items-center justify-between">
            <div className="pb-4">
              {t(
                'You can share documents with all parents/guardians listed on the student profile by choosing student name(s), room(s), or location.'
              )}
              <Popup
                content={t(
                  'Sharing a document sends an email notification to all parents/guardians listed on the student profile with a valid email address. They will see the documents when they log in to the parent portal on a web browser.'
                )}
                trigger={<InfoIcon className="size-4" />}
              />
            </div>
          </div>

          <ShowErrors errors={errors} />

          <Form id="documentShareForm" onSubmit={onSubmit} noValidate>
            <Form.Field>
              <Form.Select
                label={t('Share with Students')}
                name="selectedStudents"
                id="selectedStudents"
                placeholder={t('Enter a Student Name')}
                value={data.selectedStudents}
                onChange={updateForm}
                disabled={data.selectedRooms.length > 0 || data.shareWithEntireLocation || isSharing}
                options={studentList}
                multiple
                search
                selection
                data-testid="fs-select-students"
              />
            </Form.Field>

            <Form.Field>
              <Form.Select
                id="selectedRooms"
                name="selectedRooms"
                label={t('Share with Rooms')}
                placeholder={t('Select Room(s)')}
                multiple
                value={data.selectedRooms}
                onChange={updateForm}
                disabled={data.selectedStudents.length > 0 || data.shareWithEntireLocation || isSharing}
                options={rooms.map((room) => ({
                  value: room.id,
                  text: room.name,
                }))}
                data-testid="fs-select-rooms"
              />
            </Form.Field>

            <Form.Field>
              <Form.Checkbox
                id="shareWithEntireLocation"
                name="shareWithEntireLocation"
                checked={data.shareWithEntireLocation}
                label={t('Share with all students at your location')}
                onChange={updateForm}
                disabled={data.selectedStudents.length > 0 || data.selectedRooms.length > 0}
                data-testid="fs-loc-checkbox"
              />
            </Form.Field>

            <Form.Field>
              <Form.TextArea
                id="additionalNotes"
                name="additionalNotes"
                placeholder={t('Let everyone know what has changed in your handbook')}
                disabled={isSharing}
                value={data.additionalNotes}
                label={t("What's new?")}
                onChange={updateForm}
                error={errors.additionalNotes}
                data-testid="fs-additional-notes"
              />

              <div className="text-smaller text-right">
                {t('Characters Remaining ')} {getNotesCharactersRemaining()}
              </div>
            </Form.Field>

            <Divider />

            <Dialog.Actions>
              <div className="flex w-full justify-end gap-2">
                <Button disabled={isSharing} onClick={resetForm} type="button" data-testid="fs-clear-btn">
                  {t('Clear Selection')}
                </Button>
                <Button disabled={isSharing} primary type="submit" data-testid="fs-submit-btn">
                  {t('Share')}
                </Button>
              </div>
            </Dialog.Actions>
          </Form>
        </div>
      </Dialog.Panel>
    </Dialog>
  );
};

export default FileShareModal;
