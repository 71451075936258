import { useTranslation } from 'react-i18next';
import './FeeDisclaimer.scss';

type Props = {
  invoice?: any;
  organization?: any;
};

function getProcessingFeeDisclaimer(selectedInvoice, organization): string | null {
  const processingFeeLineItem = selectedInvoice?.invoiceItemList?.find((item) => item.processingFees !== undefined);
  if (processingFeeLineItem) {
    return 'billing.processingFeesDisclaimer.payer.parent';
  }

  const billingFees = selectedInvoice?.billingFees?.find((item) => item.processingFees !== undefined);
  if (billingFees) {
    return 'billing.processingFeesDisclaimer.payer.provider';
  }

  const payer = organization?.monetization?.payer;
  if (payer === 'parent') {
    return 'billing.processingFeesDisclaimer.payer.parent';
  }

  return 'billing.processingFeesDisclaimer.payer.provider';
}

const FeeDisclaimer: React.FC<Props> = ({ invoice, organization }) => {
  const { t } = useTranslation();

  if (!invoice && !organization) return null;

  const disclaimer = getProcessingFeeDisclaimer(invoice, organization);
  return (
    <div className="fee-disclaimer" data-testid={disclaimer}>
      {disclaimer ? t(disclaimer) : null}
    </div>
  );
};

export default FeeDisclaimer;
