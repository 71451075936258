import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Link } from 'react-router-dom';
// eslint-disable-next-line no-restricted-imports
import { Card, Header, Icon, List } from 'semantic-ui-react';
import { routes } from '../../../../config/routes';
import { formatCurrency } from '../../../../helpers/utils';
import { FeeDisclaimerContainer } from '../../../Shared/FeeDisclaimer';
import { InvoiceItem } from '../../../Shared/InvoiceItem';
import './Confirm.scss';

const Confirm = ({ paymentDetails, currentOrganization }) => {
  const { t } = useTranslation();

  return (
    <Card fluid className={'confirm-payment'}>
      <Card.Content className={'confirm-payment-header'}>
        <Card.Header textAlign="center">
          <Link to={routes.billing.invoices.edit}>
            <Icon data-testid="edit-invoice-icon" name="edit" />{' '}
            <span data-testid="edit-invoice-text">{t('billing.confirm.EditInvoice')}</span>
          </Link>
        </Card.Header>
      </Card.Content>
      <Card.Content>
        <List divided>
          <List.Item>
            <List.Content>
              <Header as="h2" className={'company-header'} data-testid="invoice-company-header">
                {currentOrganization && currentOrganization.name}
              </Header>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content floated={'right'}>
              <List.Header data-testid="invoice-due-date-label">{t('Due date')}:</List.Header>
              <List.Description data-testid="invoice-due-date-value">{paymentDetails.dateDue}</List.Description>
            </List.Content>
          </List.Item>
          {paymentDetails.invoiceSummary &&
            paymentDetails.invoiceSummary.items.map((item, index) => (
              <InvoiceItem key={`invoiceItemSummary${index}`} item={item} index={index} />
            ))}
          <List.Item>
            <List.Content>
              <List.Header>
                <div data-testid="invoice-total-label">{t('billing.confirm.InvoiceTotal')}</div>
                <div data-testid="invoice-total-value">
                  {paymentDetails.invoiceSummary && formatCurrency(paymentDetails.invoiceSummary.total)}
                </div>
              </List.Header>
            </List.Content>
          </List.Item>
        </List>
        <FeeDisclaimerContainer currentOrganization={currentOrganization} />
      </Card.Content>
    </Card>
  );
};

Confirm.propTypes = {
  paymentDetails: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    currentOrganization: state.organizations.currentOrganization,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Confirm);
