import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createSelector } from 'reselect';
// eslint-disable-next-line no-restricted-imports
import { Table } from 'semantic-ui-react';
import { routes } from '../../../../config/routes';

import { setOrganizationInvoicePlan } from '../../../../api/firebase/invoices';
import { invoiceEditSelected } from '../../../../redux/actions/billingActions';

import { useLoadedStudents } from '../../../../students/studentsHooks';
import { createArrayOfFamilyContacts } from '../../../../students/studentsUtils';

// Import style
import { InvoicePlan } from '@wonderschool/common-base-types';
import { Card } from '@wonderschool/common-base-ui';
import { InvoicePlanStatus } from '../../../../helpers/invoicePlans';
import DateTime from '../../../DateTime/DateTime';
import '../InvoiceDetail/InvoiceDetail.scss';
import { InvoicePlanActionButtons } from './InvoicePlanActionButtons';
import { InvoicePlanDetails } from './InvoicePlanDetails';
import { InvoicePlanStatusLabel } from './InvoicePlanStatusLabel';
import { InvoicePlanSummary } from './InvoicePlanSummary';

const selectSelectedRecurringInvoices = createSelector(
  [(state) => state.invoices],
  (invoices) => invoices?.recurring?.selected
);
const selectCurrentOrganization = createSelector(
  [(state) => state.organizations],
  (organizations) => organizations?.currentOrganization
);

type InvoicePlanDetailsContainerProps = {
  invoicePlan: InvoicePlan | null;
  showActionButtons?: boolean;
};

export function InvoicePlanDetailsContainer({
  invoicePlan,
  showActionButtons = true,
}: InvoicePlanDetailsContainerProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const invoicePlanFromStore: InvoicePlan | null = useSelector(selectSelectedRecurringInvoices);
  const { id: organizationId } = useSelector(selectCurrentOrganization);
  const currentOrganization = useSelector(selectCurrentOrganization);
  const [isSendingInvoicePlan, setIsSendingInvoicePlan] = useState(false);
  const [isEditingInvoicePlan, setIsEditInvoicePlan] = useState(false);

  const selectedInvoicePlan: InvoicePlan | null = invoicePlan || invoicePlanFromStore;

  const students = useLoadedStudents(
    (selectedInvoicePlan?.students && selectedInvoicePlan.students.map((student) => student.id)) || []
  );
  const responsibleForBilling = students
    .map((student) => {
      if (student.responsibleForBilling) return student.responsibleForBilling;

      const families = createArrayOfFamilyContacts(student);

      if (families.length <= 0) {
        return null;
      }

      const familyMember = families.find((parent) => !!parent.email);

      if (!familyMember) {
        return null;
      }

      const data = {
        displayName: familyMember.displayName,
        email: familyMember.email,
      };
      return data;
    })
    .filter((responsible) => !!responsible);

  if (responsibleForBilling.length <= 0)
    responsibleForBilling.push({
      displayName: `${t('billing.invoiceDetail.Noparentfound')} :(`,
      email: `${t('billing.invoiceDetail.Noemailfound')} :(`,
    });

  useEffect(() => {
    const doSendInvoicePlan = async () => {
      if (isSendingInvoicePlan) {
        await setOrganizationInvoicePlan(organizationId, selectedInvoicePlan);
        setIsSendingInvoicePlan(false);
      }
    };
    doSendInvoicePlan();
  }, [isSendingInvoicePlan, selectedInvoicePlan, organizationId]);

  useEffect(() => {
    if (isEditingInvoicePlan) {
      history.push(routes.billing.plans.edit);
    }
  }, [isEditingInvoicePlan, history]);

  const onClickEdit = useCallback(() => {
    dispatch(invoiceEditSelected(selectedInvoicePlan));
    setIsEditInvoicePlan(true);
  }, [dispatch, selectedInvoicePlan]);

  const handleChangeStatus = useCallback(
    (newStatus: InvoicePlanStatus) => {
      if (selectedInvoicePlan) {
        selectedInvoicePlan.status = newStatus;
        setIsSendingInvoicePlan(true);
      }
    },
    [selectedInvoicePlan]
  );

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row justify-between">
        <InvoicePlanStatusLabel status={selectedInvoicePlan?.status ?? ''} data-testid="invoice-plan-detail-status" />
        <div className="ml-auto mr-0 flex">
          {selectedInvoicePlan && (
            <InvoicePlanActionButtons
              showActionButtons={showActionButtons}
              loading={isSendingInvoicePlan}
              selectedInvoicePlan={selectedInvoicePlan}
              onChangeStatus={handleChangeStatus}
              onClickEdit={onClickEdit}
            />
          )}
        </div>
      </div>
      <InvoicePlanSummary
        selectedInvoicePlan={selectedInvoicePlan}
        responsibleForBilling={responsibleForBilling}
        organization={currentOrganization}
      />

      <InvoicePlanDetails selectedInvoicePlan={selectedInvoicePlan} responsibleForBilling={responsibleForBilling} />
      {(selectedInvoicePlan?.invoiceItemList?.length ?? 0) > 0 && (
        <SentInvoices selectedInvoicePlan={selectedInvoicePlan} />
      )}
    </div>
  );

  function SentInvoices({ selectedInvoicePlan }) {
    const invoices = selectedInvoicePlan?.invoices?.map((invoice) => {
      return (
        <Table.Row key={invoice.id}>
          <Table.Cell data-testid="invoice-plan-invoice-id">{invoice.id}</Table.Cell>
          <Table.Cell data-testid="invoice-plan-invoice-status">{invoice.status}</Table.Cell>
          <Table.Cell data-testid="invoice-plan-invoice-created-at">
            {invoice.createdAt && <DateTime epoch={invoice.createdAt} format={'MM/DD/YYYY'} />}
          </Table.Cell>
          <Table.Cell data-testid="invoice-plan-invoice-total">{invoice.total}</Table.Cell>
        </Table.Row>
      );
    });
    return (
      <Card
        title={
          <div className="p-2 text-3xl font-bold text-black" data-testid="invoice-plan-detail-invoices-header">
            {t('Invoices')}
          </div>
        }
      >
        <div className="flex flex-col gap-4 p-4">
          <Table singleLine basic="very">
            <Table.Body>
              <Table.Row>
                <Table.HeaderCell data-testid="invoice-plan-invoice-id">{t('Invoice ID')}</Table.HeaderCell>
                <Table.HeaderCell data-testid="invoice-plan-invoice-status">{t('common.status')}</Table.HeaderCell>
                <Table.HeaderCell data-testid="invoice-plan-invoice-created-at">{t('Created On')}</Table.HeaderCell>
                <Table.HeaderCell data-testid="invoice-plan-invoice-total">{t('Total')}</Table.HeaderCell>
              </Table.Row>

              {invoices ? (
                invoices
              ) : (
                <Table.Row key={'noInvoices'} textAlign="center">
                  <Table.Cell colSpan={4} data-testid="invoice-plan-no-invoices">
                    {t('No invoices created yet')}
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </div>
      </Card>
    );
  }
}

export default InvoicePlanDetailsContainer;
