import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useEffect, useState } from 'react';
import { estimateMonthlyRevenue } from '../api/firebase/projectedRevenue';
import { useOrganization } from './useOrganizations';

dayjs.extend(utc);

interface ProjectedRevenue {
  [key: string]: number;
}

export const useProjectedRevenue = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [projectedRevenue, setProjectedRevenue] = useState<ProjectedRevenue>({});
  const organization = useOrganization();

  useEffect(() => {
    const fetchProjectedRevenue = async () => {
      try {
        setIsLoading(true);
        const now = dayjs();
        const windowStart = now.startOf('month').valueOf();
        const windowEnd = now.add(5, 'month').endOf('month').valueOf();

        const result = await estimateMonthlyRevenue(organization?.id, windowStart, windowEnd);

        setProjectedRevenue(result);
      } catch (err) {
        setError(err instanceof Error ? err : new Error('An error occurred'));
      } finally {
        setIsLoading(false);
      }
    };

    if (organization?.id) {
      fetchProjectedRevenue();
    }
  }, [organization]);

  return { projectedRevenue, isLoading, error };
};
