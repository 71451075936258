import { buildMarketplaceLink, getPaths } from '../../navigation';
import { logError, logInfo } from '../../rollbar';

import {
  InterestedChildType,
  InterestedFamilyType,
  InterestedParentType,
  MarketplaceRevenueSourceEnum,
  MarketplaceSourceEnum,
  MarketplaceStatusEnum,
} from './types';

type BaseRequestParamsType = {
  organization: any;
  token: string;
  params?: any;
};
type MarketplaceRequestParamsType = BaseRequestParamsType & {
  path: string;
  method?: string;
  body?: any;
};

type FetchInterestedFamiliesParamsType = BaseRequestParamsType;

type PostEnrollmentStatusParamsType = BaseRequestParamsType & {
  marketplaceLeadId: string;
  enrollmentStatus: string;
};

export type MarketplaceLeadType = {
  id: number;
  children: InterestedChildType[];
  parents: InterestedParentType[];
  insertedAt: string | null;
  status: MarketplaceStatusEnum | null;
  source: MarketplaceSourceEnum | null;
  revenueSourceType: MarketplaceRevenueSourceEnum | null;
  family_id: number | null;
};

export async function fetchInterestedFamilies({
  organization,
  token,
  params = {},
}: FetchInterestedFamiliesParamsType): Promise<InterestedFamilyType[]> {
  try {
    const paths = getPaths();

    const marketplaceLeads: MarketplaceLeadType[] = await sendGetRequest<MarketplaceLeadType[]>({
      organization,
      token,
      path: paths.external.apiLeads,
      params,
    });

    const interestedFamilies: InterestedFamilyType[] = [];
    marketplaceLeads.forEach((lead) => {
      lead.children.forEach((child, index) => {
        interestedFamilies.push({
          id: lead.id,
          key: `${lead.id}-${index}`,
          student: child,
          parents: lead.parents,
          insertedAt: lead.insertedAt,
          status: lead.status,
          source: lead.source,
          revenueSource: lead.revenueSourceType,
          family_id: lead?.family_id ?? null,
        });
      });
    });
    return interestedFamilies;
  } catch (error: any) {
    logError('Fetch Interested Families', error);
    throw error;
  }
}
export async function sendEnrollmentStatus({
  organization,
  marketplaceLeadId,
  enrollmentStatus,
  token,
}: PostEnrollmentStatusParamsType) {
  const paths = getPaths();
  const params = {
    marketplaceSchoolId: organization.marketplaceSchoolId,
    leadId: marketplaceLeadId,
  };
  try {
    logInfo('Sending enrollment status', { enrollmentStatus, params });
    await sendPostRequest({ organization, token, path: paths.external.apiLead, params, body: { enrollmentStatus } });
  } catch (error: any) {
    logError('Post Enrollment Status', error);
    throw error;
  }
}
async function sendGetRequest<T>({ organization, token, path, params = {} }: MarketplaceRequestParamsType): Promise<T> {
  return sendRequest<T>({ organization, token, path, method: 'GET', params });
}
async function sendPostRequest<T>({
  organization,
  token,
  path,
  params = {},
  body = {},
}: MarketplaceRequestParamsType): Promise<T> {
  return sendRequest<T>({ organization, token, path, method: 'POST', params, body });
}

async function sendRequest<T>({
  organization,
  token,
  path,
  method,
  body,
  params = {},
}: MarketplaceRequestParamsType): Promise<T> {
  if (!organization || !token || !path) throw new Error('fetchFromMarketplace: missing required params');

  try {
    const headers = new Headers({
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    });

    const url = buildMarketplaceLink({ organization, path, params });

    const options: RequestInit = {
      method: method,
      credentials: 'include',
      headers,
    };
    if (body && method !== 'GET') options.body = JSON.stringify(body);
    const response = await fetch(url, options);

    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else {
      const error = await response.json();
      throw new Error(error.message);
    }
  } catch (error: any) {
    logError('Make API Request to Marketplace', error);
    throw new Error(error.message);
  }
}
