// eslint-disable-next-line no-restricted-imports
import { Dimmer, Loader } from 'semantic-ui-react';

interface LoadingComponentProps {
  title?: string;
}

/**
 * Shows a basic loader
 */
const LoadingComponent = ({ title }: LoadingComponentProps) => (
  <Dimmer page active inverted data-testid="loading-component">
    <Loader inline="centered">{title}</Loader>
  </Dimmer>
);

export default LoadingComponent;
