/* eslint-disable i18next/no-literal-string */
import { zodResolver } from '@hookform/resolvers/zod';
import { DayEnum } from '@wonderschool/common-base-types';
import {
  ArrowLeftIcon,
  BusinessCoachCard,
  Button,
  ButtonLink,
  Checkbox,
  CurrencyInput,
  Divider,
  EditIcon,
  Input,
  Label,
  MainContentLayout,
  Option,
  Select,
  SubtractIcon,
} from '@wonderschool/common-base-ui';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import utc from 'dayjs/plugin/utc';
import { Timestamp } from 'firebase/firestore';
import { memo, useCallback, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import { Dropdown } from '../../../Components/Shared/Dropdown';
import LoadingComponent from '../../../Components/Shared/LoadingComponent';
import { DiscountAmountType } from '../../../helpers/invoices';
import { convertToCents, convertToDollars } from '../../../helpers/money';
import { capitalizeFirstLetter } from '../../../helpers/utils';
import { useOrganization } from '../../../hooks/useOrganizations';
import { RouteNameEnum } from '../../../navigation';
import { useRoutes } from '../../../navigation/navigationHooks';
import { useStudent } from '../../../students/studentsHooks';
import { NUMBERS_OF_THE_MONTH_OPTIONS } from '../../consts';
import { addInvoiceToStudent } from '../../enrollmentsAPI';
import {
  calculateEndBillingDate,
  calculateFirstInvoiceDue,
  calculateProrateAmount,
  calculateRecurringInvoiceTotal,
  calculateStartBillingDate,
  convertScheduledDaysToWeekdayNumbers,
  createInvitationRecipientsFromFamily,
  shouldCalculateProrateAmount,
} from '../../enrollmentsUtils';
import { BillingIntervalEnum, WEEKDAYS } from '../../enums';
import { InvitationRecipientType } from '../../types';
import EditProrateAmountModal from '../modals/EditProrateAmountModal';

dayjs.extend(utc);
dayjs.extend(isSameOrBefore);

// Schema validation with translations
const createSchema = (t: (key: string) => string) =>
  z
    .object({
      parentEmail: z
        .string()
        .email({ message: t('enrollments.errors.invalidEmail') })
        .min(1, { message: t('enrollments.errors.emailRequired') }),
      billingInterval: z.string().optional(),
      billFor: z.enum(['Prior', 'Next']).optional(),
      chargeDate: z.string().optional(),
      tuition: z.number({ invalid_type_error: t('enrollments.errors.copaymentPositive') }).optional(),
      recurringFees: z
        .array(
          z.object({
            type: z.string().min(1, t('enrollments.errors.feeTypeRequired')),
            category: z.string().min(1, t('enrollments.errors.feeCategoryRequired')),
            amount: z
              .number({ invalid_type_error: t('enrollments.errors.amountPositive') })
              .min(0, t('enrollments.errors.amountPositive'))
              .nonnegative(t('enrollments.errors.amountRequired')),
            amountType: z.string().min(1, t('enrollments.errors.amountRequired')),
          })
        )
        .optional(),
      oneTimeFees: z
        .array(
          z.object({
            name: z.string().min(1, t('enrollments.errors.feeTypeRequired')),
            amount: z
              .number({ invalid_type_error: t('enrollments.errors.amountPositive') })
              .min(0, t('enrollments.errors.amountPositive'))
              .nonnegative(t('enrollments.errors.amountRequired')),
            dueDate: z
              .string()
              .min(1, t('enrollments.errors.dueDateRequired'))
              .transform((val) => new Date(val)),
          })
        )
        .optional(),
      subsidized: z.boolean().optional(),
      subsidyCopayment: z.boolean().optional(),
    })
    .superRefine((data, ctx) => {
      if (!data.subsidized || (data.subsidized && data.subsidyCopayment)) {
        if (!data.tuition) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('enrollments.errors.copaymentRequired'),
            path: ['tuition'],
          });
        }
        if (data.tuition && data.tuition <= 0) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('enrollments.errors.copaymentPositive'),
            path: ['tuition'],
          });
        }

        if (!data.billingInterval) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('enrollments.errors.billingIntervalRequired'),
            path: ['billingInterval'],
          });
        }

        if (!data.billFor) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('enrollments.errors.billForRequired'),
            path: ['billFor'],
          });
        }

        if (!data.chargeDate) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('enrollments.errors.chargeDateRequired'),
            path: ['chargeDate'],
          });
        }
      }
      return true;
    });

const EnrollmentInvoicePage = () => {
  const { t } = useTranslation();
  const params = useParams<{ studentId: string }>();
  const student = useStudent(params.studentId);
  const enrollmentDate = useMemo(
    () => new Date(dayjs.utc(student?.enrollmentDate).format('MM/DD/YYYY')),
    [student?.enrollmentDate]
  );
  const organization = useOrganization();
  const { goBack, gotoRouteByName } = useRoutes();
  const [isSaving, setIsSaving] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fixedProrateAmount, setFixedProrateAmount] = useState<number | null>(null);

  const schema = useMemo(() => createSchema(t), [t]);

  const invoiceRecipients = useMemo<InvitationRecipientType[]>(() => {
    return student ? createInvitationRecipientsFromFamily(student) : [];
  }, [student]);

  const tuitionAndFees = useMemo(() => {
    return (
      student?.enrollment?.tuitionAndFees ?? {
        tuition: {},
        fees: [],
      }
    );
  }, [student]);

  type FormValues = z.infer<typeof schema>;

  const billingIntervalOptions = useMemo(
    () => [
      { label: t('enrollments.billingWeekly'), value: BillingIntervalEnum.WEEKLY },
      { label: t('enrollments.billingBiWeekly'), value: BillingIntervalEnum.BIWEEKLY },
      { label: t('enrollments.billingMonthly'), value: BillingIntervalEnum.MONTHLY },
    ],
    [t]
  );

  const billingPeriodOptions = useMemo(
    () => ({
      weekly: [
        { label: t('enrollments.priorWeek'), value: 'Prior' },
        { label: t('enrollments.nextWeek'), value: 'Next' },
      ],
      'bi-weekly': [
        { label: t('enrollments.priorTwoWeeks'), value: 'Prior' },
        { label: t('enrollments.nextTwoWeeks'), value: 'Next' },
      ],
      monthly: [
        { label: t('enrollments.priorMonth'), value: 'Prior' },
        { label: t('enrollments.nextMonth'), value: 'Next' },
      ],
    }),
    [t]
  );

  const weekDaysOptions = useMemo(
    () => WEEKDAYS.map((day) => ({ label: t(capitalizeFirstLetter(day)), value: day })),
    [t]
  );

  const biweeklyOptions = useMemo(
    () =>
      WEEKDAYS.reduce((arr: Option[], day: DayEnum) => {
        return [
          ...arr,
          { label: t(`enrollments.first${capitalizeFirstLetter(day)}`), value: `1-${day}` },
          { label: t(`enrollments.second${capitalizeFirstLetter(day)}`), value: `2-${day}` },
        ];
      }, []),
    [t]
  );

  const chargeDateOptions = useMemo(
    () => ({
      weekly: weekDaysOptions,
      'bi-weekly': biweeklyOptions,
      monthly: NUMBERS_OF_THE_MONTH_OPTIONS,
    }),
    [biweeklyOptions, weekDaysOptions]
  );

  const feeTypeOptions = useMemo(
    () => [
      { label: t('enrollments.fee'), value: 'Fee' },
      { label: t('enrollments.discount'), value: 'Discount' },
    ],
    [t]
  );

  const discountOptions = useMemo(
    () => [
      { text: t('common.credit'), value: 'Credit' },
      { text: t('billing.dropdown.EmployeeDiscount'), value: 'Employee Discount' },
      { text: t('billing.dropdown.FinancialAid'), value: 'Financial Aid' },
      { text: t('enrollments.scholarship'), value: 'Scholarship' },
      { text: t('common.revenueSubsidy'), value: 'Subsidy' },
      { text: t('common.referral'), value: 'Referral' },
      { text: t('billing.dropdown.Sibling/FamilyDiscount'), value: 'Sibling/Family Discount' },
    ],
    [t]
  );

  const feeOptions = useMemo(
    () => [
      { text: t('revenueDiaperFee'), value: 'Diaper Fee' },
      { text: t('billing.dropdown.MealFee'), value: 'Meal Fee' },
      { text: t('enrollments.materialFee'), value: 'Material Fee' },
    ],
    [t]
  );

  const [feeCategoryOptions, setFeeCategoryOptions] = useState({
    Discount: discountOptions,
    Fee: feeOptions,
  });

  const [oneTimeFees, setOneTimeFees] = useState([
    { text: t('enrollments.enrollmentDeposit'), value: 'Enrollment/Registration deposit (refundable)' },
    { text: t('enrollments.enrollmentFee'), value: 'Enrollment/Registration fee (non-refundable)' },
    { text: t('billing.dropdown.MealFee'), value: 'Material Fee' },
    { text: t('billing.dropdown.ProcessingFee'), value: 'Processing fee' },
  ]);

  const findSelectedOption = useCallback(
    (arr: Option[], value: string) => arr?.find((option) => option.value === value),
    []
  );

  const {
    control,
    handleSubmit,
    watch,
    register,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      parentEmail: invoiceRecipients[0]?.email ?? '',
      billingInterval: tuitionAndFees?.tuition?.billingInterval ?? '',
      billFor: tuitionAndFees?.tuition?.billFor ?? 'Next',
      chargeDate: tuitionAndFees?.tuition?.detailedTuition?.chargeDate ?? '',
      tuition: tuitionAndFees?.tuition?.detailedTuition?.tuition ?? 0,
      recurringFees: tuitionAndFees?.tuition?.detailedTuition?.fees ?? [],
      oneTimeFees: tuitionAndFees?.fees ?? [],
      subsidized: tuitionAndFees?.tuition?.detailedTuition?.subsidized ?? false,
      subsidyCopayment: tuitionAndFees?.tuition?.detailedTuition?.subsidyCopayment ?? true,
    },
  });

  const {
    fields: recurringFields,
    append: addRecurringFee,
    remove: removeRecurringFee,
  } = useFieldArray({
    control,
    name: 'recurringFees',
  });

  const {
    fields: oneTimeFields,
    append: addOneTimeFee,
    remove: removeOneTimeFee,
  } = useFieldArray({
    control,
    name: 'oneTimeFees',
  });

  const recurringInvoiceTotal = calculateRecurringInvoiceTotal(watch('recurringFees'), watch('tuition'));

  const oneTimeFeeTotal = watch('oneTimeFees')?.reduce((sum, fee) => sum + (convertToDollars(fee.amount) || 0), 0) || 0;

  const selectedBillingInterval = watch('billingInterval') || '';
  const intervalBillingPeriodOptions = billingPeriodOptions[selectedBillingInterval] || [];
  const intervalChargeDatePeriodOptions = chargeDateOptions[selectedBillingInterval] || [];
  const chargeDate = watch('chargeDate');
  const billFor: 'Prior' | 'Next' = watch('billFor') || 'Next';
  const selectedBillFor =
    billingPeriodOptions[selectedBillingInterval]?.find((option) => option.value === billFor) || undefined;
  const selectedChargeDate =
    chargeDateOptions[selectedBillingInterval]?.find((option) => option.value === chargeDate) || null;

  const isCurrency = (index: number) => {
    const recurringFees = watch('recurringFees');
    return recurringFees?.[index]?.amountType === DiscountAmountType.CURRENCY;
  };

  const goToSummary = useCallback(
    () => gotoRouteByName(RouteNameEnum.STUDENT_ENROLLMENT_SUMMARY, [{ name: 'studentId', value: params.studentId }]),
    [gotoRouteByName, params.studentId]
  );

  const onSubmit = async (data: FormValues) => {
    setIsSaving(true);
    try {
      let tuition = {};

      if (!data.subsidized || (data.subsidized && data.subsidyCopayment)) {
        tuition = {
          amount: convertToCents(recurringInvoiceTotal),
          billingInterval: data.billingInterval,
          billFor: data.billFor,
          dueDate: calculateFirstInvoiceDue(
            data.chargeDate!,
            data.billingInterval as BillingIntervalEnum,
            enrollmentDate,
            data.billFor!
          ).toDate(),
          proratedAmount: Math.max(calculatedProrateAmount, 0),
          detailedTuition: {
            tuition: data.tuition,
            fees: data.recurringFees,
            chargeDate: data.chargeDate,
            subsidized: data.subsidized,
            subsidyCopayment: data.subsidyCopayment,
          },
        };
      } else {
        tuition = {
          detailedTuition: {
            subsidized: data.subsidized,
            subsidyCopayment: data.subsidyCopayment,
          },
        };
      }
      const fees = data.oneTimeFees || [];
      const tuitionAndFees = { tuition, fees };

      await addInvoiceToStudent(student, tuitionAndFees, organization.id);

      goToSummary();
    } catch (e) {
      console.error(e);
      setIsSaving(false);
    }
  };

  const onError = useCallback((errors: any, e: any) => console.error(errors, e), []);

  const currentParentEmail = watch('parentEmail');
  const setParentEmail = useCallback(
    (parent: any) => {
      setValue('parentEmail', currentParentEmail === parent.email ? '' : parent.email || '');
    },
    [currentParentEmail, setValue]
  );

  const startBillingDate = useMemo(() => {
    if (chargeDate && selectedBillingInterval && enrollmentDate && billFor) {
      return calculateStartBillingDate(
        chargeDate,
        selectedBillingInterval as BillingIntervalEnum,
        enrollmentDate,
        billFor
      );
    } else {
      return null;
    }
  }, [chargeDate, selectedBillingInterval, enrollmentDate, billFor]);

  const endBillingDate = useMemo(() => {
    if (chargeDate && selectedBillingInterval && enrollmentDate && billFor) {
      return calculateEndBillingDate(
        chargeDate!,
        selectedBillingInterval as BillingIntervalEnum,
        enrollmentDate,
        billFor
      );
    } else {
      return null;
    }
  }, [chargeDate, selectedBillingInterval, enrollmentDate, billFor]);

  const endEnrollmentRange = useMemo(() => {
    if (billFor === 'Prior') {
      if (selectedBillingInterval === BillingIntervalEnum.WEEKLY) {
        return dayjs(startBillingDate).subtract(1, 'day').toDate();
      } else {
        return dayjs(endBillingDate).toDate();
      }
    }
    return endBillingDate;
  }, [billFor, endBillingDate, selectedBillingInterval, startBillingDate]);

  const weekdayNumbers = useMemo(
    () => convertScheduledDaysToWeekdayNumbers(student?.enrollment?.scheduledDays) || [],
    [student?.enrollment?.scheduledDays]
  );

  const shouldCalculateProrate = shouldCalculateProrateAmount(
    chargeDate || '',
    selectedBillingInterval as BillingIntervalEnum,
    enrollmentDate
  );

  const calculatedProrateAmount = useMemo(() => {
    if (typeof fixedProrateAmount === 'number') {
      return fixedProrateAmount;
    }
    return shouldCalculateProrate
      ? calculateProrateAmount(
          weekdayNumbers,
          startBillingDate,
          endBillingDate,
          enrollmentDate,
          endEnrollmentRange,
          recurringInvoiceTotal || 0
        )
      : 0;
  }, [
    shouldCalculateProrate,
    weekdayNumbers,
    startBillingDate,
    endBillingDate,
    enrollmentDate,
    endEnrollmentRange,
    recurringInvoiceTotal,
    fixedProrateAmount,
  ]);

  if (!student) return <LoadingComponent />;

  const invoiceDueString = (date: string | Date | Timestamp) => {
    if (typeof date === 'string') {
      return date;
    }
    if (date instanceof Timestamp) {
      return dayjs.unix(date.seconds).utc().format('YYYY-MM-DD');
    }
    return dayjs(date).format('YYYY-MM-DD');
  };

  const saveProrateAmount = (amount) => {
    if (amount) {
      setFixedProrateAmount(parseFloat(amount));
    }
    setIsModalOpen(false);
  };

  return (
    <MainContentLayout title={t('enrollments.invoicesPageTitle')}>
      <BusinessCoachCard text={t('enrollments.coachMessage')} />
      <form onSubmit={handleSubmit(onSubmit, onError)} className="space-y-6 pt-6">
        {/* Email Invoices Section */}
        <div className="space-y-4">
          <h2 className="text-xl font-semibold">{t('enrollments.emailInvoicesTo')}</h2>
          {invoiceRecipients.map((parent, index) => (
            <div key={index} onClick={() => setParentEmail(parent)}>
              <label className="flex items-center space-x-2">
                <Checkbox
                  disabled={!parent.email || invoiceRecipients.length <= 1}
                  checked={currentParentEmail === parent.email}
                  className="form-checkbox"
                />
                <div className="flex flex-col">
                  <Label>{parent.displayName}</Label>
                  <span className="text-xs">{parent.email}</span>
                </div>
              </label>
            </div>
          ))}
          {errors.parentEmail && <p className="text-sm text-red-950">{errors.parentEmail.message}</p>}
        </div>

        <Divider />

        {/* Recurring Invoice Section */}
        <div className="space-y-4">
          <h2 className="text-xl font-semibold">{t('enrollments.recurringInvoice')}</h2>
          <div className="text-base">{t('enrollments.recurringInvoiceInstructions')}</div>
          <div className="flex items-center space-x-2">
            <Checkbox type="switch" {...register('subsidized')} label={t('enrollments.receivingSubsidizedCare')} />
          </div>

          {watch('subsidized') && (
            <div className="flex items-center space-x-2">
              <Checkbox type="switch" {...register('subsidyCopayment')} label={t('enrollments.paySubsidyCopayment')} />
            </div>
          )}

          {(!watch('subsidized') || (watch('subsidized') && watch('subsidyCopayment'))) && (
            <div className="grid grid-cols-1 gap-4 pt-2 md:grid-cols-2">
              <Controller
                name="billingInterval"
                control={control}
                render={() => (
                  <Select
                    label={t('enrollments.enrollmentInfoBillingIntervalLabel')}
                    options={billingIntervalOptions}
                    required
                    error={errors.billingInterval?.message}
                    onChange={(option: Option) => {
                      setValue('billingInterval', option.value);
                      setValue('chargeDate', undefined);
                      clearErrors('billingInterval');
                    }}
                    value={findSelectedOption(billingIntervalOptions, selectedBillingInterval)}
                  />
                )}
              />
              <div className="flex flex-col gap-2">
                <Controller
                  name={'tuition'}
                  control={control}
                  render={() => (
                    <CurrencyInput
                      label={t('enrollments.enrollmentInfoTuitionLabel')}
                      onChange={({ target }) => {
                        const value = target.value.replace(',', '');
                        setValue('tuition', parseFloat(value));
                        clearErrors('tuition');
                      }}
                      value={(() => {
                        const tuitionValue = watch('tuition');
                        return tuitionValue && typeof tuitionValue === 'number' && tuitionValue > 0
                          ? tuitionValue.toString()
                          : '';
                      })()}
                      required
                    />
                  )}
                />
                {errors.tuition && <p className="text-sm text-red-950">{errors.tuition?.message}</p>}
              </div>
              <div>
                {selectedBillingInterval === BillingIntervalEnum.BIWEEKLY ? (
                  <Input
                    type="date"
                    inputType="date"
                    {...register('chargeDate')}
                    label={t('enrollments.dueDate')}
                    className="h-9 w-full rounded-md border p-2"
                    value={watch('chargeDate')}
                    disabled={!selectedBillingInterval}
                    error={errors.chargeDate?.message}
                    required
                  />
                ) : (
                  <Controller
                    name="chargeDate"
                    control={control}
                    render={() => (
                      <Select
                        extraClasses="capitalize"
                        label={t('enrollments.dueDate')}
                        options={intervalChargeDatePeriodOptions}
                        required
                        error={errors.chargeDate?.message}
                        onChange={(option: Option) => {
                          setValue('chargeDate', option.value);
                          clearErrors('chargeDate');
                        }}
                        value={selectedChargeDate}
                        disabled={!selectedBillingInterval}
                      />
                    )}
                  />
                )}
                <div className="text-xs">{t('enrollments.chargeDateNote')}</div>
              </div>
              <div>
                <Controller
                  name="billFor"
                  control={control}
                  render={() => (
                    <Select
                      label={t('enrollments.billFor')}
                      options={intervalBillingPeriodOptions}
                      required
                      error={errors.billFor?.message}
                      onChange={(option: Option) => {
                        setValue('billFor', option.value as 'Prior' | 'Next');
                        clearErrors('billFor');
                      }}
                      value={selectedBillFor}
                      disabled={!selectedBillingInterval}
                    />
                  )}
                />
              </div>
            </div>
          )}
        </div>

        <Divider />

        {/* Recurring Fees & Discounts Section */}
        {(!watch('subsidized') || (watch('subsidized') && watch('subsidyCopayment'))) && (
          <>
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <h2 className="text-xl font-semibold">{t('enrollments.recurringFees')}</h2>
                <ButtonLink
                  type="button"
                  extraClasses="text-base"
                  onClick={() =>
                    addRecurringFee({
                      type: feeTypeOptions[0].value,
                      category: '',
                      amount: 0,
                      amountType: DiscountAmountType.CURRENCY,
                    })
                  }
                  content={t('enrollments.addFeeDiscount')}
                />
              </div>
              <div className="text-base">{t('enrollments.recurringFeesNote')}</div>

              {recurringFields.map((field, index) => (
                <div key={field.id} className="grid grid-cols-12 gap-2">
                  <div className="col-span-2">
                    <Controller
                      name={`recurringFees.${index}.type`}
                      control={control}
                      render={() => (
                        <Select
                          label={t('enrollments.feeType')}
                          options={feeTypeOptions}
                          required
                          onChange={(option: Option) => {
                            setValue(`recurringFees.${index}.type`, option.value);
                            clearErrors(`recurringFees.${index}.type`);
                          }}
                          defaultValue={feeTypeOptions[0]}
                        />
                      )}
                    />
                  </div>
                  <div className="col-span-4 flex flex-col gap-3">
                    <Label>{t('enrollments.feeCategory')}</Label>
                    <Controller
                      name={`recurringFees.${index}.category`}
                      control={control}
                      render={() => (
                        <Dropdown
                          allowAdditions
                          clearable
                          search
                          placeholder={t('Select or type new')}
                          label={t('enrollments.feeCategory')}
                          options={feeCategoryOptions[watch(`recurringFees.${index}.type`)]}
                          required
                          onChange={(_e, data) => {
                            setValue(`recurringFees.${index}.category`, data.value);
                            clearErrors(`recurringFees.${index}.category`);
                          }}
                          onAddItem={(_e, data) => {
                            const newOption = {
                              text: data.value,
                              label: data.value,
                              value: data.value,
                            };
                            setFeeCategoryOptions({
                              ...feeCategoryOptions,
                              [watch(`recurringFees.${index}.type`)]: [
                                ...feeCategoryOptions[watch(`recurringFees.${index}.type`)],
                                newOption,
                              ],
                            });
                            setValue(`recurringFees.${index}.category`, data.value);
                            clearErrors(`recurringFees.${index}.category`);
                          }}
                          value={watch(`recurringFees.${index}.category`)}
                          translator={t}
                          isForm={false}
                        />
                      )}
                    />
                  </div>
                  <div className="col-span-2 flex flex-col gap-2">
                    {watch(`recurringFees.${index}.amountType`) === DiscountAmountType.CURRENCY ? (
                      <Controller
                        name={`recurringFees.${index}.amount`}
                        control={control}
                        render={() => (
                          <CurrencyInput
                            label={t('enrollments.amount')}
                            onChange={({ target }) => {
                              const value = target.value.replace(',', '');
                              setValue(`recurringFees.${index}.amount`, parseFloat(value));
                              clearErrors(`recurringFees.${index}.amount`);
                            }}
                            value={(() => {
                              const amount = watch(`recurringFees.${index}.amount`);
                              return amount && typeof amount === 'number' && amount > 0 ? amount.toString() : '';
                            })()}
                            required
                          />
                        )}
                      />
                    ) : (
                      <Input
                        type="number"
                        {...register(`recurringFees.${index}.amount`, { valueAsNumber: true })}
                        placeholder={t('enrollments.amount')}
                        label={t('enrollments.amount')}
                        className="h-9 w-full rounded-md border"
                        value={watch(`recurringFees.${index}.amount`)?.toString()}
                      />
                    )}
                    {errors.recurringFees?.[index]?.amount && (
                      <p className="text-sm text-red-950">{errors.recurringFees[index]?.amount?.message}</p>
                    )}
                  </div>
                  <div className="col-span-3 flex items-end">
                    <Button
                      primary={isCurrency(index)}
                      onClick={() => setValue(`recurringFees.${index}.amountType`, DiscountAmountType.CURRENCY)}
                      extraClasses="h-9 w-9"
                      disabled={watch(`recurringFees.${index}.type`) === 'Fee'}
                    >
                      $
                    </Button>
                    <Button
                      primary={!isCurrency(index)}
                      onClick={() => setValue(`recurringFees.${index}.amountType`, DiscountAmountType.PERCENT)}
                      extraClasses="h-9 w-9"
                      disabled={watch(`recurringFees.${index}.type`) === 'Fee'}
                    >
                      %
                    </Button>
                  </div>
                  <div className="col-span-1 ml-4 flex items-end pb-2">
                    <SubtractIcon
                      onClick={() => removeRecurringFee(index)}
                      className="size-6 cursor-pointer text-blue-900"
                    />
                  </div>
                </div>
              ))}
            </div>

            <div className="flex w-full flex-col text-nowrap rounded-lg border border-none bg-purple-50 p-3 text-lg">
              <div className="font-semibold">
                {t('enrollments.recurringInvoiceTotal')}: ${(recurringInvoiceTotal || 0).toFixed(2)}{' '}
                {selectedBillingInterval ? `/ ${selectedBillingInterval}` : ''}
              </div>
              {calculatedProrateAmount > 0 && (
                <div className="whitespace-normal text-base">
                  {t('enrollments.prorateText', {
                    enrollmentDate: dayjs.utc(enrollmentDate).format('MM/DD/YYYY'),
                    calculatedAmount: calculatedProrateAmount.toFixed(2),
                    firstInvoiceDue: calculateFirstInvoiceDue(
                      chargeDate!,
                      selectedBillingInterval as BillingIntervalEnum,
                      enrollmentDate,
                      billFor
                    ).format('MM/DD/YYYY'),
                    tuition: (recurringInvoiceTotal || 0).toFixed(2),
                    billingInterval: selectedBillingInterval,
                  })}
                  <div className="ml-2 inline cursor-pointer text-blue-900" onClick={() => setIsModalOpen(true)}>
                    <span>
                      <EditIcon className="size-6" />
                    </span>{' '}
                    {t('enrollments.editAmount')}
                  </div>
                </div>
              )}
            </div>

            <Divider />
          </>
        )}

        {/* One-time Enrollment Fees Section */}
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-semibold">{t('enrollments.oneTimeEnrollmentFees')}</h2>
            <ButtonLink
              type="button"
              extraClasses="text-base"
              onClick={() => addOneTimeFee({ name: feeCategoryOptions.Fee[0].value, amount: 0, dueDate: new Date() })}
              content={t('enrollments.addOneTimeFee')}
            />
          </div>
          <div className="text-base">{t('enrollments.oneTimeEnrollmentFeesNote')}</div>
          {oneTimeFields.map((field, index) => (
            <div key={field.id} className="grid grid-cols-6 gap-2">
              <div className="col-span-3 flex flex-col gap-3">
                <Label>{t('enrollments.feeType')}</Label>
                <Controller
                  name={`oneTimeFees.${index}.name`}
                  control={control}
                  render={() => (
                    <Dropdown
                      allowAdditions
                      clearable
                      search
                      placeholder={t('Select or type new')}
                      label={t('enrollments.feeType')}
                      options={oneTimeFees}
                      required
                      onChange={(_e, data) => {
                        setValue(`oneTimeFees.${index}.name`, data.value);
                        clearErrors(`oneTimeFees.${index}.name`);
                      }}
                      onAddItem={(_e, data) => {
                        const newOption = {
                          text: data.value,
                          label: data.value,
                          value: data.value,
                        };
                        setOneTimeFees([...oneTimeFees, newOption]);
                        setValue(`oneTimeFees.${index}.name`, data.value);
                        clearErrors(`oneTimeFees.${index}.name`);
                      }}
                      value={watch(`oneTimeFees.${index}.name`)}
                      translator={t}
                      isForm={false}
                    />
                  )}
                />
              </div>
              <div className="flex flex-col gap-2">
                <Controller
                  name={`oneTimeFees.${index}.amount`}
                  control={control}
                  render={() => (
                    <CurrencyInput
                      label={t('enrollments.amount')}
                      onChange={({ target }) => {
                        const value = target.value.replace(',', '');
                        setValue(`oneTimeFees.${index}.amount`, convertToCents(parseFloat(value)));
                        clearErrors(`oneTimeFees.${index}.amount`);
                      }}
                      value={(() => {
                        const amount = watch(`oneTimeFees.${index}.amount`);
                        return amount && typeof amount === 'number' && amount > 0
                          ? convertToDollars(amount).toString()
                          : '';
                      })()}
                      required
                    />
                  )}
                />
                {errors.oneTimeFees?.[index]?.amount && (
                  <p className="text-sm text-red-950">{errors.oneTimeFees[index]?.amount?.message}</p>
                )}
              </div>
              <div>
                <Input
                  type="date"
                  inputType="date"
                  {...register(`oneTimeFees.${index}.dueDate`)}
                  label={t('enrollments.dueDate')}
                  className="h-9 w-full rounded-md border p-2"
                  value={invoiceDueString(watch(`oneTimeFees.${index}.dueDate`)).toString()}
                />
                <div className="text-xs">{t('enrollments.dueDateNote')}</div>
              </div>
              <div className="flex h-16 items-end pb-1">
                <SubtractIcon onClick={() => removeOneTimeFee(index)} className="size-6 cursor-pointer text-blue-900" />
              </div>
            </div>
          ))}
        </div>

        {oneTimeFeeTotal > 0 && (
          <div
            className={
              'ml-0 mr-auto flex space-x-2 text-nowrap rounded-lg border border-none bg-purple-50 p-3 text-lg font-semibold'
            }
          >
            {t('enrollments.oneTimeFeeTotal')}: ${oneTimeFeeTotal.toFixed(2)}
          </div>
        )}

        <Divider />

        <div className="mt-6 flex justify-between">
          <Button
            onClick={() => {
              goBack();
            }}
            data-testid="es-goback-btn"
            preIcon={<ArrowLeftIcon className="size-6" />}
          >
            {t('common.previous')}
          </Button>
          <div className="flex gap-4">
            <Button onClick={goToSummary}>{t('enrollments.setUpInvoicesLater')}</Button>
            <Button primary type="submit" loading={isSaving}>
              {t('enrollments.review')}
            </Button>
          </div>
        </div>
      </form>
      <EditProrateAmountModal
        amount={calculatedProrateAmount}
        billingInterval={selectedBillingInterval}
        enrollmentDate={dayjs.utc(enrollmentDate).format('MM/DD/YYYY')}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={saveProrateAmount}
      />
    </MainContentLayout>
  );
};

export default memo(EnrollmentInvoicePage);
