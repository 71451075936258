import { MealPlanningPage } from '@wonderschool/common-food-program-ui';
import { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { getTitlePrefix } from '../../../config/env';
import { RouteNameEnum, useRoutes } from '../../../navigation';
import { PAGE_NAMES, useSegmentPage } from '../../../segment';

const MealPlanning = () => {
  const { t } = useTranslation();
  const title = t('food.meals.list');
  const { gotoRouteByName } = useRoutes();
  useSegmentPage(PAGE_NAMES.foodProgramMeals);

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: title })}
      </title>
      <body className="dashboard" />
    </Helmet>
  );

  const navigateToCreateMeal = useCallback(() => {
    gotoRouteByName(RouteNameEnum.FOOD_PROGRAM_MEAL_CREATE);
  }, [gotoRouteByName]);

  return (
    <>
      {renderPageHead()}
      <MealPlanningPage
        onCreateMeal={navigateToCreateMeal}
        onEditMeal={(mealId: string) => {
          gotoRouteByName(RouteNameEnum.FOOD_PROGRAM_MEAL_UPDATE, [{ name: 'mealId', value: mealId }]);
        }}
        onRecordMeal={navigateToCreateMeal}
        onNavigateToCreateMeal={navigateToCreateMeal}
      />
    </>
  );
};
export default MealPlanning;
