import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { getWeeklyAttendance } from '../api/firebase/enrollmentProjection';
import { useOrganization } from './useOrganizations';

interface EnrollmentProjectionData {
  under2: number[];
  over2: number[];
  dobUnknown: number[];
  total: number[];
}

export const useEnrollmentProjection = () => {
  const defaultEnrollmentProjection: EnrollmentProjectionData = {
    under2: [0, 0, 0, 0, 0, 0],
    over2: [0, 0, 0, 0, 0, 0],
    dobUnknown: [0, 0, 0, 0, 0, 0],
    total: [0, 0, 0, 0, 0, 0],
  };
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [enrollmentProjection, setEnrollmentProjection] =
    useState<EnrollmentProjectionData>(defaultEnrollmentProjection);
  const organization = useOrganization();

  useEffect(() => {
    const fetchEnrollmentProjection = async () => {
      try {
        setIsLoading(true);
        const result = await getWeeklyAttendance(organization?.id, selectedDate);
        setEnrollmentProjection(result);
      } catch (err) {
        setError(err instanceof Error ? err : new Error('An error occurred'));
      } finally {
        setIsLoading(false);
      }
    };

    if (organization?.id) {
      fetchEnrollmentProjection();
    }
  }, [organization, selectedDate]);

  return { enrollmentProjection, isLoading, error, selectedDate, setSelectedDate };
};
