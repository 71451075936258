import moment from 'moment-timezone';
import { firestore } from './firebase';

/**
 * Estimates monthly revenue based on actual billing cycles and due dates
 */
export async function estimateMonthlyRevenue(organizationId, windowStart, windowEnd) {
  // Input validation
  if (!organizationId || !windowStart || !windowEnd) {
    throw new Error('Missing required parameters');
  }

  const revenueByMonth = {};

  // Initialize months in range
  const startMoment = moment(windowStart).startOf('month');
  const endMoment = moment(windowEnd).endOf('month');
  const current = startMoment.clone();
  while (current.isSameOrBefore(endMoment)) {
    const monthKey = current.format('YYYY-MM-DD');
    revenueByMonth[monthKey] = 0;
    current.add(1, 'month');
  }

  // Get active invoice plans
  const snapshot = await firestore()
    .collection('organizations')
    .doc(organizationId)
    .collection('invoicePlans')
    .where('status', '==', 'active')
    .get();

  // Process each invoice plan
  for (const doc of snapshot.docs) {
    const plan = doc.data();
    const { dateStart, dateEnd, dateDue, billingInterval, invoiceItemList } = plan;

    // Validate billing interval
    const interval = (billingInterval || '').toLowerCase();
    const validIntervals = ['monthly', 'weekly', 'biweekly', 'bi-weekly'];
    if (!interval || !validIntervals.includes(interval)) {
      console.warn(`Skipping invoice plan ${doc.id} due to invalid billing interval: ${billingInterval}`);
      continue;
    }

    // Calculate base amount for this plan
    const baseAmount = (invoiceItemList || []).reduce((sum, item) => sum + (Number(item.amount) || 0), 0);
    if (baseAmount <= 0) {
      console.warn(`Skipping invoice plan ${doc.id} due to invalid amount: ${baseAmount}`);
      continue;
    }

    // Start from first due date
    const currentDueDate = moment(dateDue);
    if (!currentDueDate.isValid()) {
      console.warn(`Skipping invoice plan ${doc.id} due to invalid due date`);
      continue;
    }

    const planEndDate = dateEnd ? moment(dateEnd) : endMoment.clone();
    if (!planEndDate.isValid()) {
      console.warn(`Skipping invoice plan ${doc.id} due to invalid end date`);
      continue;
    }

    // Process each billing cycle within the window
    let iterationCount = 0;
    const MAX_ITERATIONS = 30; // Safety limit for 6-month window

    while (
      currentDueDate.isSameOrBefore(planEndDate) &&
      currentDueDate.isSameOrBefore(endMoment) &&
      iterationCount < MAX_ITERATIONS
    ) {
      if (currentDueDate.isSameOrAfter(moment(dateStart)) && currentDueDate.isSameOrAfter(startMoment)) {
        const monthKey = currentDueDate.clone().startOf('month').format('YYYY-MM-DD');
        if (monthKey in revenueByMonth) {
          revenueByMonth[monthKey] += baseAmount;
        }
      }

      // Move to next due date based on billing interval
      switch (interval) {
        case 'monthly':
          currentDueDate.add(1, 'month');
          break;
        case 'weekly':
          currentDueDate.add(1, 'week');
          break;
        case 'biweekly':
        case 'bi-weekly':
          currentDueDate.add(2, 'weeks');
          break;
      }

      iterationCount++;
    }

    if (iterationCount >= MAX_ITERATIONS) {
      console.error(`Max iterations reached for invoice plan ${doc.id}`);
    }
  }

  return revenueByMonth;
}
