export enum MarketplaceStatusEnum {
  TOUR_CANCELED = 'tour_canceled',
  APPLIED_TO_ENROLL = 'applied_to_enroll',
  ACTIVE = 'active',
  CONTACT = 'contact',
  TOUR_MISSED = 'tour_missed',
  TOUR_UPCOMING = 'tour_upcoming',
  ADDED_BY_DIRECTOR = 'added_by_director',
  UNENROLLED = 'unenrolled',
  ARCHIVED = 'archived',
  GATHERING_UPCOMING = 'gathering_upcoming',
  GATHERING_ATTENDED = 'gathering_attended',
  ENROLLED = 'enrolled',
  INVITATION_EXPIRED = 'invitation_expired',
  INVITED_TO_ENROLL = 'invited_to_enroll',
  WAITLISTED = 'waitlisted',
  TOUR_ATTENDED = 'tour_attended',
  TOUR_ATTENDANCE_PENDING = 'tour_attendance_pending',
}

export enum MarketplaceSourceEnum {
  BOOKMARK = 'bookmark',
  APPLICATION = 'application',
  TOUR = 'tour',
  CONTACT = 'contact',
  NULL = 'null',
  EVENT_RSVP = 'event_rsvp',
  SCHOOL_WEBSITE = 'school_website',
  REQUEST_INFO = 'request_info',
  DIRECTOR = 'director',
}

export enum MarketplaceRevenueSourceEnum {
  WONDERSCHOOL = 'wonderschool',
  NETWORK = 'network',
  PROVIDER = 'provider',
  NULL = 'NULL', // MP sometimes sends this as a string
}

// This is used when mapping from the Marketplace status to the Enrollment status.
export type MarketplaceFilteredStatusType = Extract<
  MarketplaceStatusEnum,
  MarketplaceStatusEnum.ENROLLED | MarketplaceStatusEnum.UNENROLLED
>;
export type MarketplaceScheduleType = {
  day: string;
  startsAt: string;
  endsAt: string;
};
export type InterestedParentType = {
  firstName?: string;
  lastName?: string;
  email: string;
  phone?: string;
  identityId?: string;
};
export type InterestedChildType = {
  id?: number;
  firstName?: string;
  lastName?: string;
  dob?: string;
  gender?: string;
  allergies?: string;
  medications?: string;
  medical?: string;
  needs?: string;
  notes?: string;
  immunizationRecordUrl?: string;
  desiredStartDate?: string;
  desiredSchedule?: MarketplaceScheduleType[];
  mostRecentApplicationSchedule?: MarketplaceScheduleType[];
};

//The types of the fields are what we get from Marketplace.
export type InterestedFamilyType = {
  id: number;
  key: string;
  student?: InterestedChildType;
  parents?: InterestedParentType[];
  insertedAt: string | null;
  status: MarketplaceStatusEnum | null;
  source: MarketplaceSourceEnum | null;
  revenueSource: MarketplaceRevenueSourceEnum | null;
  family_id: number | null;
};
