import dayjs from 'dayjs';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DeleteDocumentConfirmation } from '@wonderschool/ccms-ui';
import {
  FileDocument,
  MalwareStatus,
  OrganizationProgramTypeEnum,
  USStateCodeEnum,
} from '@wonderschool/common-base-types';
import { DataTable, DataTableProps, DeleteIcon, Dropdown, Label } from '@wonderschool/common-base-ui';

import { US_STATE_NAMES } from './consts';

export type AdminDocument = Pick<
  FileDocument,
  'id' | 'name' | 'fileCategory' | 'fileStatus' | 'createdAt' | 'createdBy'
> & {
  states: USStateCodeEnum[];
  programTypes: OrganizationProgramTypeEnum[];
};

type AdminDocumentsTableInnerProps = {
  onFileDownload: (id: FileDocument['id']) => void;
  onFileDelete: (id: FileDocument['id']) => void;
};

type AdminDocumentsTableProps = AdminDocumentsTableInnerProps & Pick<DataTableProps<AdminDocument>, 'data'>;

export const AdminDocumentsTable: React.FC<AdminDocumentsTableProps> = ({ onFileDownload, onFileDelete, data }) => {
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState<AdminDocument | null>(null);
  const { t } = useTranslation();

  const getFileStatusTranslation = useCallback(
    (fileStatus: FileDocument['fileStatus']) => {
      return t(`common.documentFileStatus.${fileStatus}`);
    },
    [t]
  );

  const statesDisplayLabel = useCallback(
    (states: USStateCodeEnum[]) => {
      if (states.length === 0 || states.includes('ALL' as USStateCodeEnum)) return t('common.all');
      return states.map((state) => US_STATE_NAMES[state]).join(', ');
    },
    [t]
  );

  const openDeleteConfirmation = (document: AdminDocument) => {
    setDocumentToDelete(document);
    setConfirmDeleteOpen(true);
  };

  const deleteDocument = () => {
    if (documentToDelete) {
      onFileDelete(documentToDelete.id);
    }
    closeDeleteConfirmation();
  };

  const closeDeleteConfirmation = () => {
    setConfirmDeleteOpen(false);
    setDocumentToDelete(null);
  };

  return (
    <>
      <DeleteDocumentConfirmation
        isOpen={confirmDeleteOpen}
        onYes={deleteDocument}
        onNo={closeDeleteConfirmation}
        fileName={documentToDelete?.name}
        yesLabel={t('Delete')}
        noLabel={t('common.cancel')}
      />
      <DataTable
        columns={[
          {
            className: 'w-40',
            fieldName: 'name',
            label: t('common.name'),
            renderCell: (adminDocument) => {
              if (adminDocument.fileStatus !== MalwareStatus.CLEAN)
                return <Label extraClasses="inline">{adminDocument.name}</Label>;

              return (
                <a
                  href={`#${adminDocument.id}`}
                  rel="noopener noreferrer"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onFileDownload(adminDocument.id);
                  }}
                  data-testid="docs-name"
                  className="text-blue-800 hover:text-blue-950 hover:underline"
                >
                  {adminDocument.name}
                </a>
              );
            },
          },
          {
            className: 'w-40',
            fieldName: 'fileCategory',
            label: t('Category'),
            renderCell: (adminDocument) => {
              return t(`common.fileCategory.${adminDocument.fileCategory}`);
            },
          },
          {
            className: 'w-40',
            fieldName: 'states',
            label: t('common.state', { count: 100 }),
            renderCell: (adminDocument) => {
              return statesDisplayLabel(adminDocument.states);
            },
          },
          {
            className: 'w-40',
            fieldName: 'programTypes',
            label: t('common.organizationProgramType', { count: 100 }),
            renderCell: (adminDocument) => {
              return adminDocument.programTypes
                .map((programType) => t(`common.organizationProgramType.${programType}`))
                .join(', ');
            },
          },
          {
            className: 'w-40',
            fieldName: 'createdBy',
            label: t('common.createdBy'),
            renderCell: (adminDocument) => {
              const createdAt = dayjs(adminDocument.createdAt).format('MM/DD/YYYY');

              return (
                <div>
                  <h3>{t('admin.createdAtBy', { createdAt, createdBy: adminDocument.createdBy.displayName })}</h3>
                  <h4>
                    {t('common.status')}: {getFileStatusTranslation(adminDocument.fileStatus)}
                  </h4>
                </div>
              );
            },
          },
          {
            className: 'w-10',
            fieldName: 'actions',
            label: '',
            renderCell: (adminDocument) => {
              return (
                <Dropdown
                  items={[
                    {
                      label: t('Delete'),
                      icon: <DeleteIcon className="size-4" />,
                      onClick: () => openDeleteConfirmation(adminDocument),
                    },
                  ]}
                />
              );
            },
          },
        ]}
        data={data}
      />
    </>
  );
};
