import { useFlags } from 'launchdarkly-react-client-sdk';
import FeeDisclaimer from './FeeDisclaimer';

type Props = {
  currentOrganization?: any;
  currentInvoice?: any;
};

const FeeDisclaimerContainer = ({ currentOrganization, currentInvoice }: Props) => {
  const { isMonetizationV2Enabled } = useFlags();

  if (isMonetizationV2Enabled) {
    return <FeeDisclaimer organization={currentOrganization} invoice={currentInvoice} />;
  } else {
    return null;
  }
};

export default FeeDisclaimerContainer;
