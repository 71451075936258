import { NewMealRecordForm } from '@wonderschool/common-food-program-ui';
import { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { getTitlePrefix } from '../../../config/env';
import { useRoutes } from '../../../navigation';
import { PAGE_NAMES, useSegmentPage } from '../../../segment';

const MealRecordCreate = () => {
  const { t } = useTranslation();
  const title = t('food.meals.record.create');
  const { goBack } = useRoutes();

  useSegmentPage(PAGE_NAMES.foodProgramMealRecordCreate);

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: title })}
      </title>
      <body />
    </Helmet>
  );

  const goToMealRecordCreate = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <>
      {renderPageHead()}
      <div className="h-[calc(100vh-173px)] max-w-7xl overflow-hidden">
        <div className={twMerge('flex size-full flex-col overflow-y-auto')}>
          <NewMealRecordForm initialStep={0} onCancel={goBack} onCompletedSaving={goToMealRecordCreate} />
        </div>
      </div>
    </>
  );
};
export default MealRecordCreate;
