import { Spinner, WidgetSizeEnum } from '@wonderschool/common-base-ui';
import classNames from 'classnames/bind';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useEnrollmentProjection } from '../../hooks/useEnrollmentProjection';
import { ShowErrors } from '../Messages';
import DatePicker from '../Shared/DatePicker/DatePicker';
import styles from './EnrollmentProjection.module.scss';

const cx = classNames.bind(styles);

const WEEKDAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

const EnrollmentProjection = () => {
  const { t } = useTranslation();
  const { enrollmentProjection, isLoading, error, selectedDate, setSelectedDate } = useEnrollmentProjection();

  const handleDateChange = (event, { value }) => {
    // DatePicker returns date in MM/DD/YYYY format, we need YYYY-MM-DD
    const newDate = dayjs(value, 'MM/DD/YYYY').format('YYYY-MM-DD');
    setSelectedDate(newDate);
  };

  if (error) {
    return <ShowErrors content={error.message} />;
  }

  if (isLoading) {
    return (
      <div className={cx('spinnerContainer')}>
        <Spinner size={WidgetSizeEnum.MEDIUM} />
      </div>
    );
  }

  return (
    <div className={cx('container')}>
      <div className={cx('table')}>
        <div className={cx('tableHeader')}>
          <h3 className="text-xl">{t('enrollmentProjection.header.title')}</h3>
          <div className={cx('datePickerContainer')}>
            <DatePicker
              name="enrollmentDate"
              value={dayjs(selectedDate).format('MM/DD/YYYY')}
              onChange={handleDateChange}
              minDate={dayjs().format('MM/DD/YYYY')}
              closable
            />
          </div>
        </div>
        <div className={cx('tableContent')}>
          {/* Header Row */}
          <div className={cx('row', 'headerRow')}>
            <div className={cx('cell', 'labelCell')}></div>
            {WEEKDAYS.map((day, index) => (
              <div key={index} className={cx('cell')}>
                <span className={cx('fullDayName')}>{t(day)}</span>
                <span className={cx('shortDayName')}>{t(`enrollmentProjection.column.${day.charAt(0)}`)}</span>
              </div>
            ))}
            <div className={cx('cell')}>
              <span className={cx('fullDayName', 'scheduleNotRecorded')}>
                <span>{t('enrollmentProjection.column.scheduleNotRecorded.line1')}</span>
                <span>{t('enrollmentProjection.column.scheduleNotRecorded.line2')}</span>
              </span>
              <span className={cx('shortDayName')}>{t('enrollmentProjection.column.scheduleNotRecordedShort')}</span>
            </div>
          </div>
          {/* Under 2 Row */}
          <div className={cx('row')}>
            <div className={cx('cell', 'labelCell')}>{t('enrollmentProjection.row.under2')}</div>
            {enrollmentProjection.under2.map((count, index) => (
              <div key={index} className={cx('cell')}>
                {count}
              </div>
            ))}
          </div>
          {/* Over 2 Row */}
          <div className={cx('row')}>
            <div className={cx('cell', 'labelCell')}>{t('enrollmentProjection.row.over2')}</div>
            {enrollmentProjection.over2.map((count, index) => (
              <div key={index} className={cx('cell')}>
                {count}
              </div>
            ))}
          </div>
          {/* DOB Unknown Row */}
          <div className={cx('row')}>
            <div className={cx('cell', 'labelCell')}>
              <span className={cx('fullText')}>{t('enrollmentProjection.row.dateOfBirth')}</span>
              <span className={cx('shortText')}>{t('enrollmentProjection.row.dateOfBirthShort')}</span>
            </div>
            {enrollmentProjection.dobUnknown.map((count, index) => (
              <div key={index} className={cx('cell')}>
                {count}
              </div>
            ))}
          </div>
          {/* Total Row */}
          <div className={cx('row', 'totalRow')}>
            <div className={cx('cell', 'labelCell')}>{t('enrollmentProjection.row.total')}</div>
            {enrollmentProjection.total.map((count, index) => (
              <div key={index} className={cx('cell')}>
                {count}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnrollmentProjection;
