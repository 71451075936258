import { EthnicityEnum, GenderEnum, RaceEnum, UpdateStudentInput } from '@wonderschool/common-food-program-data-access';
import dayjs from 'dayjs';
import { phoneNumberFormat } from '../../../../helpers/utils';
import { logError } from '../../../../rollbar';
import { getRandomStudentPicture } from '../../../studentsUtils';
import { StudentFormType } from '../studentForm.type';

export function transformStudentFormTypeForUpdateMutation(
  studentFormValues: StudentFormType,
  avatarUrl?: string | null
): UpdateStudentInput['studentInput'] {
  const { doctorPhone, birthDate, gender, race, ethnicity, nickname, childId, ...rest } = studentFormValues;

  let safeDoctorPhoneNumber: string | undefined = undefined;

  if (!childId) {
    logError(
      `Child ID is required for updating student [${studentFormValues.firstName} ${studentFormValues.lastName}]`
    );
  }

  try {
    safeDoctorPhoneNumber = phoneNumberFormat(doctorPhone);
  } catch (error) {
    logError(`Invalid doctor phone number [${doctorPhone}] was set!`);
  }

  return {
    birthDate: dayjs(birthDate).toDate(),
    doctorPhone: safeDoctorPhoneNumber, // safeDoctorPhoneNumber,
    avatarUrl: avatarUrl || getRandomStudentPicture(),
    gender: gender?.value.toUpperCase() as GenderEnum | undefined,
    race: race?.value.toUpperCase() as RaceEnum | undefined,
    ethnicity: ethnicity?.value.toUpperCase() as EthnicityEnum | undefined,
    nickname,
    childId: childId?.toString() || undefined,
    ...rest,
  };
}
